import {
  isSameDay,
  isBefore,
  isAfter,
  compareAsc,
  addDays,
  isValid,
} from 'date-fns'
import castArray from 'lodash/castArray'
import {
  type HierarchicalCategory,
  type Product,
  type ProductMetadata,
  type ProductAPI,
  type ProductCampaignsPromotion,
  type NutritionalDetails,
  type NutritionalWarning,
  type PdpProduct,
} from '@/features/shop/services/Product/types'
import { objectStorageUrl } from '@/features/shared/utils/objectStorageUrl'

export const getNormalizedQuantity = (product: Partial<ProductAPI>): number => {
  const {
    quantity = 0,
    product_type: type,
    has_custom_label: hasCustomLabel,
    unit_weight: weight = 1,
  } = product ?? {}

  if (!quantity) return 0
  if (type !== PRODUCT_TYPES.BY_WEIGHT) return quantity
  if (!hasCustomLabel) return quantity
  const unitWeight = weight || 1

  // Due to floating point arithmetic precision issues in JS, sometimes
  // the quantity divided by the "unit weight" results in strangly long
  // floats. To avoid this in the UI, we must format our float using
  // fixed-point notation (toFixed()) and coerce it back into a Number.
  return Number((quantity / unitWeight).toFixed(0))
}

export const normalizeProduct = (
  product: ProductAPI,
  meta: ProductMetadata = {}
): Product => {
  const {
    description = '',
    product_id: id = 0,
    brand_name: brandName = '',
    display_name: displayName = '',
    price = 0,
    on_sale: onSale = false,
    sale_price: salePrice = 0,
    bogo = false,
    bought = false,
    featured = false,
    featured_source: featuredSource = null,
    size = '',
    product_type: type = '',
    is_custom_product: isCustomProduct = false,
    unit_weight: unitWeight,
    has_custom_label: hasCustomLabel = false,
    unit_of_measure: unitOfMeasure,
    beacons = {},
    cpg_promotions,
    oos = false,
    note = '',
    purchase_limit: purchaseLimit = 0,
    available = false,
    low_stock: lowStock = false,
    is_ebt: isEbt,
    is_snap_ebt_eligible: isSnapEbtEligible = false,
    store_location_id: storeLocationId = 0,
    store_id: storeId = 0,
    parent = null,
    parent_product_id: parentProductId = null,
    variation_display_name: variationDisplayName = '',
    variation_parent: variationParent = false,
    variation_color: variationColor = null,
    variation_size: variationSize = null,
    swatch_url: swatchUrl = null,
    for_you = false,
    universal_id,
    hierarchical_categories,
    search_boost_aliases,
    featured_transaction_id,
    zero_markup,
    review_stats,
  } = product
  let {
    name = '',
    coupon_id: couponId = '',
    description_label: descriptionLabels,
  } = product
  // convert nullable to empty array
  const cpgPromotions = cpg_promotions || []

  descriptionLabels = descriptionLabels || []

  const fallbackProductImage = objectStorageUrl('special-request.webp')
  let image: Product['image']

  if (product.image) {
    image = {
      original_size_url: product.image.original_size_url,
      url: product.image.webp_url ?? product.image.url ?? fallbackProductImage,
    }
  } else if (product.images?.length) {
    const firstImage = product.images[0]
    image = {
      url: firstImage?.webp_url ?? firstImage?.url ?? fallbackProductImage,
    }
  } else if (product.image_url) {
    image = { url: product.image_url }
  } else {
    image = { url: fallbackProductImage }
  }

  // Some products are sold by weight (e.g. packaged steaks, deli meats,
  // apples, etc.); some of those "by weight" products also have a custom
  // label override.
  //
  // In the Cart actions, we declared that if a product is "by weight", we
  // would use the Shipt-defined "unit weight" to represent the product's
  // singular quantity used to add/remove the product from the cart. This
  // means the cart's quantity for this type of product would be the "unit
  // weight" *times* the number of "units" added.
  //
  // Because of this, when we calculate *our* local cart quantity of a "by
  // weight" product, we must derive the quantity by dividing the API cart
  // product quantity by the product's "unit weight".
  //
  // Products "by weight" *without* a custom label override have a default
  // "unit weight" of 0.5; meaning that a singular quantity of this product
  // would 0.5; we handle this slighly differently. (See the Cart selectors
  // for more info.)
  //
  // If you are confused by all of this, you are not alone; we are
  // doing too much work on the client to calculate accurate "by weight"
  // product quantities. This is work that should be done in the cart service
  // to provide us accurate quantities for different product types.

  const normalizedProductQuantity = getNormalizedQuantity(product)
  let totalPrice = price
  let totalSalePrice = salePrice
  let unitLabel = ''

  if (type === PRODUCT_TYPES.BY_WEIGHT) {
    if (hasCustomLabel) {
      // Weighted products with a custom label are dynamic in their total cost
      // (think of pre-packaged meats that vary in cost due to their weight). The
      // total price of these products needs to be based on the product's unit weight.
      totalPrice = (price ?? 0) * (unitWeight ?? 0)
      totalSalePrice = (salePrice ?? 0) * (unitWeight ?? 0)
    }

    // We need to check for "<= 1" (not just "=== 1") due to the fact that
    // "by weight" products without a custom label have a half-unit step,
    // which means they could have a "0.5" unit of measure quantity.
    //
    // All products seem to come back with description labels, but we only
    // want to use those custom labels if the product is "by weight".
    if (normalizedProductQuantity <= 1) {
      unitLabel = descriptionLabels[0] || '' // singular custom label
    } else {
      unitLabel = descriptionLabels[1] || '' // plural custom label
    }
  }

  // cpg promotions take precedence over coupons
  if (cpgPromotions.length) couponId = ''

  // An item should only be BOGO if both the bogo flag and onSale flag is true.
  const isBogo = bogo && onSale

  name = isCustomProduct ? name : displayName
  const cpgPromotion = getFirstPromotion(cpgPromotions)

  // We want variation products to inherit the purchaseLimit and couponId from the parent product
  const normalizeVariationProducts = (variationProduct: ProductAPI) =>
    normalizeProduct({
      ...variationProduct,
      purchase_limit: purchaseLimit,
      coupon_id: couponId,
    })

  const variationProducts =
    meta.variationProducts?.map(normalizeVariationProducts) ||
    parent?.variation_products.map(normalizeVariationProducts)

  return {
    id: Number(id),
    name: name ?? description ?? '',
    brandName: brandName ?? '',
    oos: oos ?? false,
    purchaseLimit: purchaseLimit ?? 0,
    couponId: couponId ?? '',
    // price is the unit price of the product
    price: Number(price),
    totalPrice: totalPrice ?? 0,
    note: note ?? '',
    onSale: !!onSale && !!salePrice,
    salePrice: salePrice ?? 0,
    totalSalePrice: totalSalePrice ?? 0,
    bogo: isBogo ?? false,
    bought: bought ?? false,
    featured: featured ?? false,
    featuredSource,
    size: size ?? '',
    beacons: beacons ?? {},
    quantity: normalizedProductQuantity,
    image,
    unitLabel,
    type: type ?? '',
    isCustomProduct: isCustomProduct ?? false,
    unitWeight: unitWeight ?? 0,
    unitOfMeasure: unitOfMeasure ?? '',
    descriptionLabels,
    hasCustomLabel: hasCustomLabel ?? false,
    cpgPromotion,
    available: available ?? false,
    lowStock: lowStock ?? false,
    /**
     *  Mapping 'is_ebt' (Aviator) to 'is_snap_ebt_eligible' (Search) since they're same
     */
    is_snap_ebt_eligible: isEbt ?? isSnapEbtEligible ?? false,
    storeLocationId: storeLocationId ?? 0,
    storeId: storeId ?? 0,
    parent,
    variationDisplayName: variationDisplayName ?? '',
    variationParent: variationParent ?? false,
    parentProductId,
    variationColor,
    variationSize,
    swatchUrl: swatchUrl ?? '',
    for_you: for_you ?? false,
    variationProducts: variationProducts ?? [],
    universal_id: universal_id ?? '',
    hierarchical_categories: hierarchical_categories ?? {},
    search_boost_aliases: search_boost_aliases ?? [],
    featuredTransactionId: featured_transaction_id,
    isZeroMarkup: Boolean(zero_markup),
    review_stats: review_stats
      ? {
          average_rating: review_stats.average_rating ?? 0,
          total_reviews: review_stats.total_reviews ?? 0,
          rating_distribution: review_stats.rating_distribution ?? null,
          recommended_percentage: review_stats.recommended_percentage ?? 0,
        }
      : undefined,
  }
}

export const normalizePdpProduct = (
  product: ProductAPI,
  meta?: ProductMetadata
): PdpProduct => {
  const { nutritional_details = { products: [] } } = product
  const { variationProducts } = meta ?? {}
  return {
    ...normalizeProduct(product),
    bulletPoints: product.bullet_points ?? [],
    description: product.description ?? '',
    allergenInfo: product.allergen_info ?? '',
    chokingHazard: product.choking_hazard ?? '',
    line_item_promotion: product.line_item_promotion ?? {},
    secondaryImages: product.secondary_images ?? [],
    nutrition_attributes_for_you: product.nutrition_attributes_for_you ?? [],
    nutritionalDetails: normalizeNutritionalDetails(nutritional_details),
    nutritionalWarnings: normalizeNutritionalWarnings(nutritional_details),
    ingredients: product.ingredients ?? '',
    variationProducts: variationProducts?.length
      ? variationProducts.map((p) => normalizePdpProduct(p))
      : [],
  }
}

const getFirstPromotion = (cpgPromotions?: ProductCampaignsPromotion[]) => {
  if (!cpgPromotions?.length) {
    return null
  }

  // Elastic Search returns stale feature promotions
  // which we don't want to store in Jotai
  const today = new Date()
  cpgPromotions = cpgPromotions
    .filter((data) => {
      const startDate = new Date(data.start_date)
      // campaigns api sends the end_date formatted as YYYY/MM/DD. new Date() converts the date to the previous day
      // adding 1 day to restore the correct promotion end date
      const endDate = addDays(new Date(data.end_date), 1)
      return (
        (isSameDay(today, startDate) || isBefore(startDate, today)) &&
        (!isValid(endDate) ||
          isSameDay(today, endDate) ||
          isAfter(endDate, today))
      )
    })
    .sort((promotionA, promotionB) =>
      compareAsc(
        new Date(promotionB.start_date),
        new Date(promotionA.start_date)
      )
    )

  return cpgPromotions[0]
}

export const PRODUCT_TYPES = {
  NORMAL: 'normal',
  BY_WEIGHT: 'by weight',
  CUSTOM_BY_WEIGHT: 'custom by weight',
}

export const getProductType = (product: Product): string | undefined => {
  const { type, hasCustomLabel } = product

  switch (type) {
    case 'normal':
      return PRODUCT_TYPES.NORMAL
    case 'by weight':
      if (hasCustomLabel) {
        return PRODUCT_TYPES.CUSTOM_BY_WEIGHT
      } else {
        return PRODUCT_TYPES.BY_WEIGHT
      }
    default:
      return undefined
  }
}

const normalizeNutritionalDetails = (
  details: NutritionalDetails | null
): NutritionalDetails => {
  return {
    ...details,
    products: castArray(details?.products || []), // API sometimes returns .products as an object, so castArray wraps with array if necessary
  }
}

const normalizeNutritionalWarnings = (
  nutritionalDetails: NutritionalDetails | null
): NutritionalWarning[] => {
  /* prism `product` not always guaranteed to have the following, but may:
  "nutritional_details":{
    "products":[
      {"audience":"adult", "ingredients":"Popcorn, Vegetable Oil, Cheddar Cheese", "name":"Smartfood Popcorn White Cheddar Cheese", "warnings":"Contains milk ingredients."},
      {...},
    ]
  }
  */
  // API sometimes returns .products as an object, so castArray wraps with array if necessary
  const nutritionalProducts = castArray(nutritionalDetails?.products || [])
  return nutritionalProducts.filter((prod) => prod.warnings)
}

/**
 * When a member updates the quantity of an item (normal product type) in an existing order, we send the entire
 * order_lines to the backend with changed product's quantity updated. However, the backend expects weighted products
 * with a custom label to be in terms of weight (e.g. 13lbs of Turkey instead of 1 Turkey), so even if weighted products
 * did not have their quantity updated, we must "prepare" the quantity for these items for the backend by sending the quantity
 * as `quantity * unitWeight`. The root problem is that the backend should probably accept each unit label as a unit of quantity.
 * E.g. 1 Turkey, not 13lbs of Turkey. Orders PATCH API expects the new quantity in weight for "by weight" products
 * that do not have a unitLabel of `lb` or `lbs`. This means the Orders API
 * expects NEW_QUANTITY * UNIT_WEIGHT for the order_lines request payload. For example, if
 * a product is "by weight", but has the label "Turkeys". When a user changes their quantity
 * from one Turkey to two Turkeys, the backend expects 2 * 13 lbs or 26.
 * @param product
 * @returns the quantity that should be sent as the payload to either the orders
 */
export const getRequestedQuantityForProduct = (product: Product) => {
  let requestedQuantity = Number(product.quantity)
  // The backend expects the quantity to be in unitWeight for custom weighted products
  if (getProductType(product) === PRODUCT_TYPES.CUSTOM_BY_WEIGHT) {
    requestedQuantity = requestedQuantity * product.unitWeight
  }
  return requestedQuantity
}

export const normalizeUnitLabel = (
  product: Product,
  quantity: number
): string => {
  if (product.type === PRODUCT_TYPES.BY_WEIGHT) {
    // descriptionLabels will always be an array (empty arr if not applicable) for product labels
    // where the first item is singular (eg. "Package") and the second item is plural (eg. "Packages")
    return quantity > 1
      ? product.descriptionLabels[1] || ''
      : product.descriptionLabels[0] || ''
  } else {
    return product.unitLabel || ''
  }
}

export const getUnitLabel = (product: Product, value: number): string => {
  const { unitLabel = '' } = product
  const unit = unitLabel.toLowerCase()

  if (unit.includes('package')) {
    return value > 1 ? 'pkgs' : 'pkg'
  }

  return normalizeUnitLabel(product, value)
}

export const getProductKey = (product: Product) =>
  product.isCustomProduct ? product.name : product.id

export const getHighestCategoryId = (
  hierarchicalCategories: HierarchicalCategory = {}
) => {
  const firstCatName =
    Object.keys(hierarchicalCategories).sort((a, b) => a.localeCompare(b))[0] ||
    ''
  const firstCat = hierarchicalCategories[firstCatName]?.[0] || ''
  return Number(firstCat.split('_')[0]) || 0
}
