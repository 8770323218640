import { track } from '@/features/shared/analytics/trackingQueue'
import { EBTVerificationFlow } from '@/features/purchase/components/Modal/PurchaseModals/constants'
import { PCI_TRACKING_NAME } from '@/features/purchase/components/PciSections/constants'

// eslint-disable-next-line no-restricted-imports -- this is used outside of React context
import { getJotaiStore } from '@/features/shared/state'
import { featureFlagsAtom } from '@/features/shared/state/FeatureFlags/atoms'
import { routeDataAtom } from '@/features/shared/state/RouteData/atoms'
import {
  getItem,
  removeItem,
  setItem,
} from '@/features/shared/utils/localStorage'
import { logWarn } from '@/features/shared/utils/logger'
import { routes, type ShiptRoute } from '@shared/constants/routes'
import { isOnServer } from '@shared/constants/util'
import {
  AnalyticsEvent,
  pciQueueDestinationEvents,
  type AllAnalyticsEvents,
} from '@shipt/analytics-member-web'
import { SubscriptionPlanType } from '@/features/account/services/SubscriptionAux/types'

export const getMembershipCopy = (planType: string) => {
  let title = 'Review and purchase'
  let buttonText = 'Confirm purchase'
  switch (planType) {
    case SubscriptionPlanType.EBT:
    case SubscriptionPlanType.STUDENT:
      title = `You're verified!`
      break
    case SubscriptionPlanType.DOWNGRADE: {
      title = 'Switch to a monthly plan'
      buttonText = 'Confirm'
      break
    }
    case SubscriptionPlanType.UPGRADE:
      title = 'Upgrade to an annual plan'
      buttonText = 'Confirm upgrade'
      break
    case SubscriptionPlanType.EARLY_RENEWAL:
      title = 'Renew annual membership'
      break
  }
  return { title, buttonText }
}

export const getMembershipDescription = ({
  verificationFlow,
  ebtCardLastFour,
  isStudent,
  refundCard,
}: {
  verificationFlow?: string
  ebtCardLastFour?: string
  isStudent?: boolean
  refundCard?: string
}) => {
  if (isStudent) {
    return 'You can redeem a student membership for up to 4 years, with annual verification required.'
  }

  if (refundCard) {
    return (
      <>
        You will be switched to a monthly plan immediately. We'll refund{' '}
        <strong>{refundCard}</strong> for any remaining days of your current
        plan, if applicable.
      </>
    )
  }

  if (!verificationFlow) return ''

  switch (verificationFlow) {
    case EBTVerificationFlow.NewCard:
      return `Your EBT card ending in ${ebtCardLastFour} has been validated and added to your account.`
    case EBTVerificationFlow.UpdatedCard:
      return `Your EBT card ending in ${ebtCardLastFour} has been validated and the card on your account was updated.`
    case EBTVerificationFlow.ExistingUnverifiedCard:
      return `Your EBT card ending in ${ebtCardLastFour} has been validated.`
    case EBTVerificationFlow.ExistingVerifiedCard:
    default:
      return 'Your SNAP-eligible status has been validated on your account.'
  }
}

// Unfortunately I have to keep this planId type until we're fully migrated to new sub aux service
export const goToSecuredMembershipPage = ({
  planId,
  verificationFlow,
  ebtLastFour,
  isRatingFlow,
}: {
  planId?: string | number | null
  verificationFlow?: string
  ebtLastFour?: string
  isRatingFlow?: boolean
}) => {
  let url = `${routes.SECURE_ADD_MEMBERSHIP_PAYMENT.url}?planId=${planId}`
  if (verificationFlow) {
    url += `&verflow=${verificationFlow}`
  }
  if (ebtLastFour) {
    url += `&lf=${ebtLastFour}`
  }
  if (isRatingFlow) {
    url += `&isRatingFlow=true`
  }
  window.location.href = url
}

export const flushPciTrackingQueue = () => {
  const jotaiStore = getJotaiStore()
  const routeMatch = jotaiStore.get(routeDataAtom)
  if (routeMatch?.pciSecured) return
  const storageEventQueue = getStoredEvents()
  try {
    while (storageEventQueue.length) {
      const nextEvent = storageEventQueue.shift()
      if (nextEvent) track({ ...nextEvent, isPostPciEvent: true })
      // for each loop, save the state of the queue after sending each event
      // this is to prevent potential lost events if an error were to happen while processing events
      setItem(PCI_TRACKING_NAME, JSON.stringify(storageEventQueue))
    }
    removeItem(PCI_TRACKING_NAME)
  } catch {
    logWarn(
      'Failed to send all PCI queued events. Will try again upon refresh',
      {
        tag: 'pci',
      }
    )
  }
}
const getStoredEvents = (eventToAdd?: string): AllAnalyticsEvents[] => {
  let storageEventQueue: AllAnalyticsEvents[] = []
  try {
    const pciQueue = getItem(PCI_TRACKING_NAME)
    const parsedQueue = pciQueue ? JSON.parse(pciQueue) : []
    if (!Array.isArray(parsedQueue)) {
      logWarn('Found non array PCI queue. Resetting storage', {
        tag: 'pci',
      })
      removeItem(PCI_TRACKING_NAME)
    } else {
      storageEventQueue = parsedQueue
    }
  } catch {
    logWarn(
      'Failed to parse pciQueue JSON from localStorage. Resetting queue',
      eventToAdd ? { eventName: eventToAdd, tag: 'pci' } : { tag: 'pci' }
    )
    removeItem(PCI_TRACKING_NAME)
  }
  return storageEventQueue
}
export const appendToPciQueue = (eventToQueue: AllAnalyticsEvents) => {
  // I know this variable is pretty much useless
  // But I added it just to make it easier to understand why we return boolean
  const isEventQueued = false
  if (isOnServer() || !(eventToQueue.eventName in pciQueueDestinationEvents))
    return isEventQueued

  const jotaiStore = getJotaiStore()
  const isPciActive =
    jotaiStore.get(featureFlagsAtom).flags.web_pci_2025_popup_compliance
  if (!isPciActive) return isEventQueued

  const routeMatch = jotaiStore.get(routeDataAtom)

  if (!routeMatch?.pciSecured) return isEventQueued

  const storageEventQueue = getStoredEvents(eventToQueue.eventName)

  try {
    setItem(
      PCI_TRACKING_NAME,
      JSON.stringify([eventToQueue, ...storageEventQueue])
    )
  } catch {
    logWarn(
      'Failed to send all PCI queued events. Will try again upon refresh',
      {
        tag: 'pci',
      }
    )
  }

  return !isEventQueued
}

export const trackPciPagesViewed = (
  location: string,
  sublocation: ShiptRoute
) => {
  track({
    eventName: AnalyticsEvent.ElementViewed,
    properties: {
      location,
      display_sublocation: sublocation.trackingName,
      content: 'add_payment',
      message_goal: 'add_card_or_digital_wallet',
      type: 'page',
    },
  })
}
