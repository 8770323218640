import { type NextApiRequestCookies } from 'next/dist/server/api-utils'
import {
  type ReactNode,
  useMemo,
  useContext,
  useReducer,
  useCallback,
  createContext,
} from 'react'
import { GuestDataStore } from '@/features/shared/constants/global'
import { isOnServer } from '@shared/constants/util'
import { cookieName as legacyAuthCookieName } from '@shared/constants/auth'
import {
  setCookie,
  getCookie,
  removeCookie,
} from '@/features/shared/utils/cookies'
import { environment } from '@/features/shared/utils/environment'
import { type GuestStore } from '@/features/shop/services/ShoppingStore/types'
import { type ValidAddress } from '@/features/account/services/Addresses/types'
import { isJson } from '@/features/shared/utils/isJson'
import { type GetServerSidePropsContext } from 'next'
import { logInfo } from '@/features/shared/utils/logger'
import { useRouter } from 'next/router'
import { userIsDoNotShareSellTA } from '@/features/account/services/DataPrivacy/utils'
import { useMutationHandleOptOutRestrictions } from '@/features/account/services/DataPrivacy/mutations'

const { STORE, ADDRESS } = GuestDataStore

type Props = {
  // this will be undefined for pages that are not using getServerSideProps
  cookies: NextApiRequestCookies | undefined
  children: ReactNode
}
export const GuestUserContextProvider = ({ cookies, children }: Props) => {
  const [counter, forceUpdate] = useReducer((c) => c + 1, 0)
  const router = useRouter()
  const { mutateAsync: handleOptOutRestrictions } =
    useMutationHandleOptOutRestrictions()
  const { [legacyAuthCookieName]: isAuthUser } = cookies ?? {}

  const setGuestCookie = useCallback(
    <TKey extends GuestDataStore>(
      key: TKey,
      val: TKey extends GuestDataStore.STORE ? GuestStore : ValidAddress
    ) => {
      if (isAuthUser) {
        logInfo('Trying to set guest cookie for authenticated user', {
          callLocation: router.pathname,
        })
        return
      }
      setCookie(key, val, {
        secure: environment() === 'local' ? false : true,
        expires: 7,
      })
      forceUpdate()
    },
    [isAuthUser, router.pathname]
  )

  const setGuestAddressCookie = useCallback(
    async (address: ValidAddress) => {
      if (isAuthUser || userIsDoNotShareSellTA()) {
        return setGuestCookie(GuestDataStore.ADDRESS, address)
      }
      try {
        await handleOptOutRestrictions({
          state: address.state,
          zip_code: address.zip_code,
        })
      } finally {
        setGuestCookie(GuestDataStore.ADDRESS, address)
      }
    },
    [handleOptOutRestrictions, setGuestCookie, isAuthUser]
  )

  const setGuestStoreCookie: GuestUserContextType['setGuestStoreCookie'] =
    useCallback(
      (store) => {
        // ensure that only these properties are set on the cookie to ensure its as small as possible
        const {
          id,
          name,
          image,
          circular_image,
          background_color,
          metro_id,
          is_pickup,
          store_location_id,
          next_delivery_window,
        }: GuestStore = store
        setGuestCookie(GuestDataStore.STORE, {
          id,
          name,
          image,
          circular_image,
          background_color,
          metro_id,
          is_pickup,
          store_location_id,
          next_delivery_window,
        })
      },
      [setGuestCookie]
    )

  const clearGuestCookie = useCallback(() => {
    if (getCookie(STORE) || getCookie(ADDRESS)) {
      removeGuestCookies()
      forceUpdate()
    }
  }, [])

  const { guestStore, guestAddress } = useMemo(() => {
    const getCookieValue = <TValue,>(
      key: GuestDataStore
    ): TValue | undefined => {
      try {
        if (isOnServer()) {
          const cookie = cookies?.[key]
          return isJson(cookie) ? JSON.parse(cookie) : undefined
        }
        return getCookie(key)
      } catch {
        return undefined
      }
    }
    return {
      counter,
      guestStore: getCookieValue<GuestUserParams['guestStore']>(STORE),
      guestAddress: getCookieValue<GuestUserParams['guestAddress']>(ADDRESS),
    }
    // we purposely want to recompute the values when the counter state is updated
  }, [cookies, counter])

  const value = useMemo<GuestUserContextType>(() => {
    return {
      counter,
      guestStore,
      guestAddress,
      clearGuestCookie,
      setGuestStoreCookie,
      setGuestAddressCookie,
    }
  }, [
    counter,
    guestStore,
    guestAddress,
    clearGuestCookie,
    setGuestStoreCookie,
    setGuestAddressCookie,
  ])

  return (
    <GuestUserContext.Provider value={value}>
      {children}
    </GuestUserContext.Provider>
  )
}

export const removeGuestCookies = () => {
  removeCookie(ADDRESS)
  removeCookie(STORE)
}

export type GuestUserParams = {
  guestStore: GuestStore | undefined
  guestAddress: ValidAddress | undefined
}

export type GuestStoreParamsSSR = GuestUserParams & {
  ssrContext?: GetServerSidePropsContext
}

type GuestUserContextType = GuestUserParams & {
  counter: number
  clearGuestCookie: () => void
  setGuestStoreCookie: (selectedStore: GuestStore) => void
  setGuestAddressCookie: (address: ValidAddress) => Promise<void>
}

const GuestUserContext = createContext<GuestUserContextType | null>(null)

export const useGuestUserContext = () => useContext(GuestUserContext)
