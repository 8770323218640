import { defaultFlags } from '@/features/shared/services/FeatureFlags/constants'
import { atom } from 'jotai'

export interface FeatureFlagsState {
  isPending: boolean
  isFetched: boolean
  error: unknown
  flags: typeof defaultFlags
}

export const featureFlagsAtom = atom<FeatureFlagsState>({
  isPending: true,
  isFetched: false,
  error: null,
  flags: defaultFlags,
})
featureFlagsAtom.debugLabel = 'featureFlagsAtom'
