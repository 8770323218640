import { useCallback } from 'react'
import { track } from '@/features/shared/analytics/trackingQueue'
import { AnalyticsEvent } from '@shipt/analytics-member-web'
import { urlPathnameToLocationName } from '@/features/shared/analytics/utils'
import { useMetroId } from '@/features/account/services/User/hooks'
import { getBenefitDetails } from '@/features/shared/constants/visa'
import {
  type Subscription,
  type SubscriptionPlanResponse,
  SubscriptionId,
  type SubscriptionResponse,
  MembershipInterval,
} from '@/features/account/services/MembershipPlan/types'
import { trackFirstPurchased } from '@/features/shared/analytics/users'
import {
  getMembershipType,
  getSubscriptionInformation,
} from '@/features/account/components/Membership/utils'
import {
  epochSecondsToISO,
  toMembershipPlan,
} from '@/features/account/services/MembershipPlan/utils'
import { type VisaBenefitDetails } from '@/features/account/services/VisaBenefits/types'
import {
  SubscriptionPlanType,
  type AuxPurchasePlan,
} from '@/features/account/services/SubscriptionAux/types'

type PlanTypeViewedProps = {
  name: string
  isPromo?: boolean
  upgradeTerm?: string
  isTrial?: boolean
  displaySublocation?: string
}

type PlanTypeClickedProps = {
  name: string
  price?: number
  isPromo?: boolean
  isTrial?: boolean
  planType?: string
  upgradeTerm?: string
  planId?: string
  displaySublocation?: string
}

export const useTrackPlanType = (isModal = true) => {
  const displayType = isModal ? 'modal' : 'page'
  const metroId = useMetroId()

  const trackPlanTypeViewed = useCallback(
    (eventProperties: PlanTypeViewedProps) => {
      const { name, isPromo, upgradeTerm, isTrial, displaySublocation } =
        eventProperties
      track({
        eventName: AnalyticsEvent.PlanTypeViewed,
        properties: {
          location: urlPathnameToLocationName(),
          display_type: displayType,
          content: name,
          is_promo: Boolean(isPromo),
          upgrade_term: upgradeTerm ?? name,
          is_trial: Boolean(isTrial),
          display_sublocation: displaySublocation,
        },
      })
    },
    [displayType]
  )

  const trackPlanTypeClicked = useCallback(
    (eventProperties: PlanTypeClickedProps) => {
      const {
        name,
        price,
        isPromo,
        isTrial,
        planType,
        upgradeTerm,
        planId,
        displaySublocation,
      } = eventProperties
      track({
        eventName: AnalyticsEvent.PlanTypeClicked,
        properties: {
          location: urlPathnameToLocationName(),
          metro_id: metroId,
          display_type: displayType,
          plan_id: planId ?? '',
          plan_id_billing_amount: price ?? 0,
          is_promo: Boolean(isPromo),
          is_trial: Boolean(isTrial),
          content: planType ?? SubscriptionPlanType.STANDARD,
          upgrade_term: upgradeTerm ?? name,
          display_sublocation: displaySublocation,
        },
        eventOptions: {
          sendImmediately: true,
        },
      })
    },
    [displayType, metroId]
  )

  return {
    trackPlanTypeViewed,
    trackPlanTypeClicked,
  }
}

export enum PlanSublocation {
  CHECKBOX = 'checkbox',
  TRIAL_AUTO_DIRECT = 'trial_auto_direct',
}

export enum SpecialtyPlanSublocation {
  PLAN_SECTION = 'plan_section',
  DISCOUNTED_PAGE_BUTTON = 'discounted_page_button',
}

type PlanStartedProps = {
  subscription?: SubscriptionResponse | AuxPurchasePlan | null
  from?: PlanSublocation | SpecialtyPlanSublocation
  isModal?: boolean
  isGift?: boolean
  discount?: number
  promoCode?: string
  visaBenefits?: VisaBenefitDetails
  index?: number
  upgradeTerm?: string
  sendImmediately?: boolean
}

const formatPlanDate = (time?: number | null) => epochSecondsToISO(Number(time))

export const formatPlanType = (
  plan: SubscriptionPlanResponse | Subscription,
  visaBenefits?: VisaBenefitDetails
) => {
  const { id, interval } = plan
  const intervalCount =
    'intervalCount' in plan ? plan.intervalCount : plan.interval_count
  const membershipType = getMembershipType(
    interval ?? MembershipInterval.year,
    intervalCount ?? 1
  ).toLocaleLowerCase()

  // default to annual plan
  let planType: string = id ?? SubscriptionId.GROCERY_YEARLY
  let planTerm = membershipType === 'membership' ? 'annual' : membershipType

  const isVisa = planType === SubscriptionId.VISA_MONTHLY && visaBenefits
  const isStudent = planType === SubscriptionId.STUDENT_MONTHLY
  const isSnapEBT = planType === SubscriptionId.SNAP_EBT_MONTHLY

  if (isVisa) {
    const card = getBenefitDetails(visaBenefits.type)

    const duration = card.durationText
    const discountedDuration = card.discountedDurationText

    planTerm = `${duration}_free`
    if (discountedDuration) {
      planTerm = `${planTerm}_${discountedDuration}_50%`
    }

    planType =
      (card.durationMonths ?? 1) % 12 === 0 ? 'visa_yearly' : 'visa_monthly'
  } else if (isStudent) {
    planTerm = 'Student'
    planType = 'Student'
  } else if (isSnapEBT) {
    planType = 'ebt'
    planTerm = 'ebt'
  }

  // if plan is a monthly type, we only return grocery_monthly without any pricing info
  if (planType.indexOf(SubscriptionId.GROCERY_MONTHLY) === 0) {
    planType = SubscriptionId.GROCERY_MONTHLY
  }

  return {
    planTerm: planTerm.toLocaleLowerCase().replace(/ /g, '_'),
    planType: planType.toLocaleLowerCase(),
  }
}

export const trackPlanStarted = ({
  subscription,
  from,
  isModal = true,
  isGift = false,
  discount,
  promoCode,
  visaBenefits,
  index,
  upgradeTerm,
}: PlanStartedProps) => {
  if (!subscription) return
  const {
    current_period_start,
    current_period_end,
    plan,
    trial_start,
    trial_end,
  } = subscription
  const location = urlPathnameToLocationName()
  const planStart = formatPlanDate(current_period_start)
  const planEnd = formatPlanDate(current_period_end)
  const trialStart = formatPlanDate(trial_start)
  const trialEnd = formatPlanDate(trial_end)
  const isTrial = Boolean(trialStart || trialEnd)
  const { planType, planTerm } = formatPlanType(
    subscription.plan ?? {},
    visaBenefits
  )
  const isVisa = subscription.plan?.id === SubscriptionId.VISA_MONTHLY
  const billingAmount = isVisa ? 0 : plan?.amount ?? 0

  track({
    eventName: AnalyticsEvent.PlanStarted,
    properties: {
      location,
      display_sublocation: from,
      display_format: isModal ? 'modal' : 'page',
      list_index: index,
      plan_term: planTerm,
      plan_type: planType,
      billing_amount: billingAmount,
      billing_amount_dollars: billingAmount,
      ...(discount && {
        discount_amount: discount,
      }),
      ...(promoCode && {
        promotion_code: promoCode,
      }),
      plan_start_date: planStart,
      plan_end_date: planEnd,
      is_trial: isTrial,
      ...(isTrial && {
        trial_start_date: trialStart,
        trial_end_date: trialEnd,
      }),
      is_gifted: isGift,
      upgrade_term: upgradeTerm,
      subscription_id: plan?.id || '',
    },
    eventOptions: {
      sendImmediately: true,
    },
  })
}

export const trackPlanStartedAndFirstPurchased = ({
  subscription,
  from,
  isModal,
  isGift,
  discount,
  visaBenefits,
  index,
  upgradeTerm,
}: PlanStartedProps) => {
  if (!subscription) return

  trackPlanStarted({
    subscription,
    from,
    isModal,
    isGift,
    discount,
    visaBenefits,
    index,
    upgradeTerm,
  })
  trackFirstPurchased(subscription.plan)
}

export const trackPlanCancelled = (
  prevSubscription: Subscription,
  visaBenefits?: VisaBenefitDetails
) => {
  const { startDate, renewalDate, trialStart, trialEnd, id } = prevSubscription
  const isTrial = Boolean(trialStart || trialEnd)
  const { planTerm, planType } = formatPlanType(prevSubscription, visaBenefits)

  track({
    eventName: AnalyticsEvent.PlanCancelled,
    properties: {
      plan_term: planTerm,
      plan_type: planType,
      plan_start_date: startDate,
      plan_end_date: renewalDate,
      is_trial: isTrial,
      is_gifted: false,
      ...(isTrial && {
        trial_start_date: trialStart,
        trial_end_date: trialEnd,
      }),
      subscription_id: id,
    },
  })
}

export const trackPlanReactivated = (
  subscriptionResponse: SubscriptionResponse,
  sublocation?: string
) => {
  const location = urlPathnameToLocationName()
  const subscription = toMembershipPlan(subscriptionResponse)
  const { planType } = getSubscriptionInformation(subscription)

  track({
    eventName: AnalyticsEvent.PlanReactivated,
    properties: {
      plan_end_date: subscription.renewalDate,
      plan_start_date: subscription.startDate,
      plan_type: planType,
      location: sublocation || location,
    },
  })
}
