import {
  addCloudModeIntegrations,
  setDeviceModeIntegrations,
} from '@/features/shared/analytics/integrations'
import ShiptAnalytics, {
  type AllAnalyticsEvents,
  type AnalyticsEvent,
  getAnonymousId,
  getCampaignInfo,
  getSessionInfo,
} from '@shipt/analytics-member-web'
import { type ShiptEventOptions } from '@shipt/analytics-core'
import { v4 as uuidv4 } from 'uuid'
import { isOnServer } from '@shared/constants/util'
import { useState } from 'react'
import {
  getGuestTrait,
  getPageData,
  getUserTraits,
  shouldDisableAnalytics,
  shouldSendToGoogle,
} from '@/features/shared/analytics/utils'
import {
  handleMarvAdviceCookieExpiration,
  MARV_ADVICE_BY_CAMPAIGN,
} from '@/features/shared/utils/marv'
import { getCookie } from '@/features/shared/utils/cookies'
import { getUser } from '@/features/account/services/User/utils'
import { getQueryClient } from '@/features/shared/utils/dataFetching/reactQuery/SegwayQueryClient'
import { getDataRightsTrait } from '@/features/account/services/DataPrivacy/utils'
import { appendToPciQueue } from '@/features/purchase/components/PciSections/utils'

export const getTrackOptions = ({
  eventName,
  traits,
}: {
  eventName: AnalyticsEvent
  traits?: Record<string, unknown>
}): Partial<ShiptEventOptions> => {
  const userData = getUser(getQueryClient())
  const { sessionId } = getSessionInfo()
  return {
    anonymousId: getAnonymousId(),
    context: {
      linkKey: uuidv4(),
      // @ts-expect-error we want to attach an UUID to each event so that it has
      // the same UUID across the inhouse SDK and the segment SDK
      traits: {
        ...traits,
        ...(userData && {
          ...getUserTraits(userData),
        }),
        ...getDataRightsTrait(),
        ...getGuestTrait(),
      },
      campaign: getCampaignInfo(getPageData()),
      sessionId,
    },
    integrations: {
      ...setDeviceModeIntegrations(eventName),
      ...addCloudModeIntegrations(),
    },
    ...(userData?.id && { userId: String(userData.id) }),
  }
}

export const track = (
  event: AllAnalyticsEvents & {
    eventOptions?: Partial<ShiptEventOptions>
    traits?: Record<string, unknown>
    // This prop is only used to determine if this event was a queued event after a PCI page
    isPostPciEvent?: boolean
  }
) => {
  const { eventName, properties, eventOptions, traits, isPostPciEvent } = event
  if (isOnServer() || shouldDisableAnalytics()) return
  handleMarvAdviceCookieExpiration()
  const options = {
    ...getTrackOptions({
      eventName,
      traits,
    }),
    ...eventOptions,
  }
  const marvCookieValue = getCookie(MARV_ADVICE_BY_CAMPAIGN)

  const eventProperties: AllAnalyticsEvents['properties'] = {
    ...properties,
    ...(marvCookieValue && {
      marv_advice_by_campaign: JSON.stringify(marvCookieValue),
    }),
  }

  // We can ONLY fire ShiptAnalytics events within PCI pages.
  // All other analytics libraries will fire the second the user lands on a non-PCI page
  // The sendPciTrackingQueue will take care of adding to the queue and firing after the fact
  const isEventQueued = appendToPciQueue(event)
  if (!isEventQueued) {
    // https://support.google.com/tagmanager/answer/7679219?hl=en&ref_topic=7679108
    if (shouldSendToGoogle(eventName)) {
      window.dataLayer?.push({ event: eventName, ...eventProperties })
    }
    window.analytics?.track(eventName, eventProperties, options)
  }

  !isPostPciEvent && ShiptAnalytics.track(eventName, eventProperties, options)
}

export const useTrackingQueue = () => {
  const [eventQueue, setEventQueue] = useState<AllAnalyticsEvents[]>([])
  const trackQueue = (event: AllAnalyticsEvents) => {
    if (isOnServer()) return
    setEventQueue([...eventQueue, event])
  }
  const sendAllQueuedEvents = () => {
    if (isOnServer() || !eventQueue.length) return
    while (eventQueue.length) {
      const event = eventQueue.shift()
      if (event) track(event)
    }
  }

  return {
    eventQueue,
    trackQueue,
    sendAllQueuedEvents,
  }
}
