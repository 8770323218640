import { type SignupPlansResponse } from '@/features/account/services/MembershipPlan/types'
import { apiGet } from '@/features/shared/utils/dataFetching'
import { type QueryFunctionContext } from '@tanstack/react-query'
import { type SignupPlansKey } from '@/features/shop/services/DeliveryPlans/queries'

export const fetchSignupPlans = (
  context: QueryFunctionContext<SignupPlansKey>
) => {
  const [, params] = context.queryKey
  const ssrContext = context.meta?.ssrContext

  let referringUrl = params.referring_url || ''
  if (!referringUrl) {
    if (ssrContext) {
      referringUrl = ssrContext.req.headers.referer || ''
    } else if (typeof document !== 'undefined') {
      referringUrl = document.referrer || ''
    }
  }

  return apiGet<SignupPlansResponse>({
    config: {
      url: 'signup_promotions/find_plans',
      params: {
        ...params,
        referring_url: referringUrl,
      },
    },
    context,
    fetcherName: 'fetchSignupPlans',
  })
}
