import {
  createContext,
  useContext,
  type ReactNode,
  useMemo,
  useCallback,
} from 'react'
import { type Content } from '@/features/cms/components/CMS/types'
import {
  trackElementClicked,
  trackElementViewed,
} from '@/features/shared/analytics/element'
import {
  type ElementClicked,
  type ElementViewed,
} from '@shipt/analytics-member-web'
import {
  type PromoCardEvent,
  trackPromoCardClicked,
  trackPromoCardViewed,
} from '@/features/shared/analytics/promoCard'

type Props = { data: Content; children: ReactNode }

const CMSContext = createContext<Content | null>(null)

export const CMSContextProvider = ({ children, data }: Props) => {
  return <CMSContext.Provider value={data}>{children}</CMSContext.Provider>
}

export const useCMSContext = () => useContext(CMSContext)

const useCMSTrackingProperties = () => {
  const data = useCMSContext()
  return useMemo(
    () =>
      data
        ? {
            display_sublocation: data.analytic_slug,
            shelf_name:
              (data.data && 'heading' in data.data && data.data.heading) ||
              undefined,
            shelf_type: data?.content_type_id,
          }
        : {},
    [data]
  )
}

export const useCMSTrackingEvents = () => {
  const cmsProperties = useCMSTrackingProperties()

  const trackCMSElementViewed = useCallback(
    (eventProperties: Omit<ElementViewed['properties'], 'location'>) => {
      trackElementViewed({ ...eventProperties, ...cmsProperties })
    },
    [cmsProperties]
  )

  const trackCMSElementClicked = useCallback(
    (eventProperties: Omit<ElementClicked['properties'], 'location'>) => {
      trackElementClicked(
        { ...eventProperties, ...cmsProperties },
        { sendImmediately: true }
      )
    },
    [cmsProperties]
  )

  const trackCMSPromoCardViewed = useCallback(
    (eventProperties: Omit<PromoCardEvent, 'location'>) => {
      trackPromoCardViewed({ ...eventProperties, ...cmsProperties })
    },
    [cmsProperties]
  )

  const trackCMSPromoCardClicked = useCallback(
    (eventProperties: Omit<PromoCardEvent, 'location'>) => {
      trackPromoCardClicked(
        { ...eventProperties, ...cmsProperties },
        { sendImmediately: true }
      )
    },
    [cmsProperties]
  )

  return {
    trackCMSElementViewed,
    trackCMSElementClicked,
    trackCMSPromoCardClicked,
    trackCMSPromoCardViewed,
  }
}
