import { PCI_ROUTE_ERROR } from '@/features/shared/utils/appStart/usePciHardRouting'

/**
 * Error messages we want to globally ignore and filter out of Bugsnag
 * logging entirely. Think VERY CAREFULLY before ignoring something here.
 */
export const globalIgnoredMessages: (string | RegExp)[] = [
  'Failed to fetch', // failed to fetch 3rd party resources for pinterest, tiktok, etc
  'Network Error', // Axios logs for every API error
  PCI_ROUTE_ERROR.message, // ignore due to manually throwing routing error in usePciHardRouting
]
