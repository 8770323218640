export function PrivacyIcon() {
  return (
    <svg
      width="30"
      height="14"
      viewBox="0 0 30 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99995 12.8002H13.8L16.9 1.2002H6.99995C3.79995 1.2002 1.19995 3.8002 1.19995 7.0002C1.19995 10.2002 3.79995 12.8002 6.99995 12.8002Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2 0.000244141H7C3.1 0.000244141 0 3.10024 0 7.00024C0 10.9002 3.1 14.0002 7 14.0002H22.2C26.1 14.0002 29.2 10.9002 29.2 7.00024C29.2 3.10024 26 0.000244141 22.2 0.000244141ZM1.2 7.00024C1.2 3.80024 3.8 1.20024 7 1.20024H16.9L13.8 12.8002H7C3.8 12.8002 1.2 10.2002 1.2 7.00024Z"
        fill="#0066FF"
      />
      <path
        d="M24.2 4.00022C24.4 4.20022 24.4 4.60022 24.2 4.80022L22.1 7.00022L24.3 9.20022C24.5 9.40022 24.5 9.80022 24.3 10.0002C24.1 10.2002 23.7 10.2002 23.5 10.0002L21.3 7.80022L19.1 10.0002C18.9 10.2002 18.5 10.2002 18.3 10.0002C18.1 9.80022 18.1 9.40022 18.3 9.20022L20.4 7.00022L18.2 4.80022C18 4.60022 18 4.20022 18.2 4.00022C18.4 3.80022 18.8 3.80022 19 4.00022L21.2 6.20022L23.4 4.00022C23.6 3.80022 24 3.80022 24.2 4.00022Z"
        fill="white"
      />
      <path
        d="M12.3001 4.1002C12.5001 4.3002 12.6001 4.7002 12.4001 4.9002L8.2001 9.8002C8.1001 9.9002 8.0001 10.0002 7.9001 10.0002C7.7001 10.1002 7.4001 10.1002 7.2001 9.9002L5.0001 7.7002C4.8001 7.5002 4.8001 7.1002 5.0001 6.9002C5.2001 6.7002 5.6001 6.7002 5.8001 6.9002L7.6001 8.6002L11.4001 4.1002C11.6001 3.9002 12.0001 3.9002 12.3001 4.1002Z"
        fill="#0066FF"
      />
    </svg>
  )
}
