import { fetchAllExperiments } from '@/features/shared/services/Experiments/fetcher'
import { useUser } from '@/features/account/services/User/hooks'
import { useSession } from '@/features/authentication/utils/authentication/hooks'
import {
  getStoreParamsSSR,
  useStoreParams,
  type StoreParamsAsKey,
} from '@/features/shared/utils/dataFetching/storeParams'
import { useQuery, type QueryClient, queryOptions } from '@tanstack/react-query'
import { type GetServerSidePropsContext } from 'next'
import { type User } from '@/features/account/services/User/types'
import { noop } from '@/features/shared/utils/noop'
import { MembershipStatus } from '@/features/account/services/MembershipPlan/types'

const getExperimentsQueryKey = (params: ExperimentParams) => {
  return ['Aristotle experiments', params] as const
}
export type ExperimentsQueryKey = ReturnType<typeof getExperimentsQueryKey>

const getExperimentsOptions = {
  staleTime: Infinity,
}

type ExperimentParams = StoreParamsAsKey & {
  has_purchased: boolean | undefined
  has_placed_first_order: boolean | undefined
  has_subscription: boolean
  is_exempt: boolean
  plan_id: string | undefined
  user_id: number | undefined
  subscription_type: string | undefined | null
}

export const experimentsQueryOptions = (params: ExperimentParams) =>
  queryOptions({
    queryKey: getExperimentsQueryKey(params),
    queryFn: fetchAllExperiments,
  })

export const useQueryExperiments = () => {
  const storeParams = useStoreParams()
  const user = useUser()
  const { has_purchased, has_placed_first_order, id } = user || {}

  const { isSessionLoading, isSessionValid } = useSession()
  return useQuery({
    ...experimentsQueryOptions({
      storeParams,
      has_purchased,
      has_placed_first_order,
      has_subscription: user?.subscription?.status === MembershipStatus.active,
      is_exempt: Boolean(user?.exempt),
      plan_id: user?.subscription?.plan?.id,
      user_id: id,
      subscription_type: user?.subscription?.type,
    }),
    placeholderData: (previousData) => previousData,
    queryFn: fetchAllExperiments,
    // isSessionValid == false for guest and new user.
    // isSessionValid == true for logged in user. id truthiness indicates whether we're currently fetching user or not.
    // We don't want to fetch experiments for a logged in user until we fetch user details
    enabled: !isSessionLoading && (!isSessionValid || Boolean(id)),
    ...getExperimentsOptions,
  })
}

export type FetchExperimentsSSR = {
  queryClient: QueryClient
  ssrContext: GetServerSidePropsContext
  user?: User
}

export const fetchExperimentsSSR = async ({
  queryClient,
  ssrContext,
  user,
}: FetchExperimentsSSR) => {
  const storeParams = getStoreParamsSSR(ssrContext, user)
  const { has_purchased, has_placed_first_order, id } = user ?? {}
  return (
    queryClient
      .fetchQuery({
        ...experimentsQueryOptions({
          storeParams,
          has_purchased,
          has_placed_first_order,
          has_subscription:
            user?.subscription?.status === MembershipStatus.active,
          is_exempt: Boolean(user?.exempt),
          plan_id: user?.subscription?.plan?.id,
          user_id: id,
          subscription_type: user?.subscription?.type,
        }),
        ...getExperimentsOptions,
      })
      // catch errors from this service so that SSR pages can still render
      .catch(noop)
  )
}
