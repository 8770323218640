import { queryOptions, useQuery } from '@tanstack/react-query'
import {
  useIsUserExempt,
  useUserId,
} from '@/features/account/services/User/hooks'
import {
  fetchEBTEnrollments,
  fetchMembershipPlan,
  fetchStudentEnrollments,
  fetchSubscriptionHistory,
} from '@/features/account/services/MembershipPlan/fetchers'
import { type QueryOptions } from '@/features/shared/utils/dataFetching/types'

export type MembershipPlanQueryKey = readonly [string, { user_id: number }]

export const membershipPlanQueryOptions = (userId: number) =>
  queryOptions({
    queryKey: ['Membership Plan', { user_id: userId }] as const,
    queryFn: fetchMembershipPlan,
  })

export const useQueryMembershipPlan = (options?: QueryOptions) => {
  const userId = useUserId()

  return useQuery({
    ...membershipPlanQueryOptions(userId ?? 0),
    enabled: Boolean(userId),
    ...options,
  })
}

const subscriptionHistoryQueryOptions = (userId: number) =>
  queryOptions({
    queryKey: ['Subscription History', { user_id: userId }] as const,
    queryFn: fetchSubscriptionHistory,
  })

export type SubscriptionHistoryQueryKey = readonly [string, { user_id: number }]

export const useQuerySubscriptionHistory = () => {
  const userId = useUserId()
  const isUserExempt = useIsUserExempt()

  return useQuery({
    ...subscriptionHistoryQueryOptions(userId ?? 0),
    enabled: Boolean(!isUserExempt && userId),
  })
}

export const studentEnrollmentsQueryOptions = queryOptions({
  queryKey: ['Student Enrollments'] as const,
  queryFn: fetchStudentEnrollments,
})

export const useQueryStudentEnrollments = (isEnabled = true) => {
  const userId = useUserId()
  return useQuery({
    ...studentEnrollmentsQueryOptions,
    enabled: Boolean(userId) && isEnabled,
  })
}

export const ebtEnrollmentsQueryOptions = queryOptions({
  queryKey: ['EBT Enrollments'] as const,
  queryFn: fetchEBTEnrollments,
})

export const useQueryEBTEnrollments = (isEnabled = true) => {
  const userId = useUserId()
  return useQuery({
    ...ebtEnrollmentsQueryOptions,
    enabled: Boolean(userId) && isEnabled,
  })
}
