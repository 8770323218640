import { getHasSignupPromotionPlans } from '@/features/authentication/services/SignupPromotions/utils'
import { useCallback, useState } from 'react'
import { signupPlansQueryOptions } from '@/features/shop/services/DeliveryPlans/queries'
import { queryOptions, useQuery, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { fetchCouponCode } from '@/features/authentication/services/SignupPromotions/fetchers'
import { type ParsedUrlQuery } from 'querystring'
import { useGetInitialSignupQueryParams } from '@/features/shop/services/DeliveryPlans/hooks'

export const useCheckEligibleSignupPromotions = (enteredEmail?: string) => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const { getInitialSignupParams } = useGetInitialSignupQueryParams()

  return useCallback(async () => {
    const coupon = queryClient.getQueryData(
      couponCodeQueryOptions(router.query).queryKey
    )
    // If a coupon plan is available, it's unnecessary to fetch available plans
    if (coupon?.code_group) return true

    return queryClient
      .fetchQuery(signupPlansQueryOptions(getInitialSignupParams(enteredEmail)))
      .then(getHasSignupPromotionPlans)
      .catch(() => false)
  }, [router.query, queryClient, getInitialSignupParams, enteredEmail])
}

/**
 * useQueryCouponCode
 */
export const couponCodeQueryOptions = (queryParams: ParsedUrlQuery) => {
  const { gift_code } = queryParams
  return queryOptions({
    queryKey: ['Coupon Code', { gift_code: String(gift_code) }] as const,
    queryFn: fetchCouponCode,
  })
}
export type CouponCodeQueryKey = readonly [string, { gift_code: string }]

export const useQueryCouponCode = () => {
  const router = useRouter()
  const { gift_code } = router.query

  return useQuery({
    ...couponCodeQueryOptions(router.query),
    enabled: Boolean(gift_code),
    refetchOnWindowFocus: false,
  })
}

/**
 * useFetchQueryCouponCode
 */
export const useFetchQueryCouponCode = () => {
  const queryClient = useQueryClient()
  const [isLoading, setIsLoading] = useState(false)

  const validateCouponCode = (gift_code: string) => {
    setIsLoading(true)
    try {
      return queryClient.fetchQuery(couponCodeQueryOptions({ gift_code }))
    } finally {
      setIsLoading(false)
    }
  }

  return { validateCouponCode, isLoading }
}
