import { type QueryFunctionContext } from '@tanstack/react-query'
import axios, {
  type AxiosError,
  type AxiosRequestConfig,
  type AxiosResponse,
} from 'axios'
import { getAuthClient } from '@/features/authentication/utils/authentication/client'
import {
  apiGatewayURL,
  getRequestParams,
  legacyApiGatewayURL,
} from '@/features/shared/utils/dataFetching/url'
import { type StoreParams } from '@/features/shared/utils/dataFetching/storeParams'
import { getAnonymousId } from '@shipt/analytics-member-web'
import { getCreateCookieAnonymousId } from '@/features/shared/serverUtils/getCreateCookieAnonymousId'
import {
  buildErrorMetadata,
  logDataFetchingErrors,
} from '@/features/shared/utils/dataFetching/utils'
import { API_URL } from '@/features/shared/utils/dataFetching/apiUrl'
import { updateMarvAdviceByCampaignCookie } from '@/features/shared/utils/marv'
import {
  isProduction,
  isRunningTests,
} from '@/features/shared/utils/environment'
import { getUniversalId } from '@/features/shared/services/Experiments/utils'
import { type GetServerSidePropsContext } from 'next'
import { isOnServer } from '@shared/constants/util'
import http from 'http'
import https from 'https'
import {
  SHUTDOWN_HANDLER,
  ShutdownCleanup,
} from '@/features/shared/serverUtils/appStop/shutdownCleanup'
import { getDataRightsTrait } from '@/features/account/services/DataPrivacy/utils'
import { getCommonRequestHeaders } from '@/features/shared/utils/dataFetching/requestHeaders'

const keepAlive = process.env.ENABLE_HTTP_KEEP_ALIVE === 'true'

let httpAgent: http.Agent | undefined
let httpsAgent: https.Agent | undefined

const shouldPassHttpAgents = isOnServer() && keepAlive

if (shouldPassHttpAgents) {
  httpAgent = new http.Agent({ keepAlive })
  httpsAgent = new https.Agent({ keepAlive })
  // close all keep alive connections when server is shut down
  ShutdownCleanup.registerHandler(SHUTDOWN_HANDLER.HTTP_AGENT, () => {
    httpAgent?.destroy()
    httpsAgent?.destroy()
  })
}

export type ApiError<TError = { error?: Error | unknown } | unknown> =
  AxiosError<TError>

type MarvAdviceByCampaign = { marv_advice_by_campaign?: Record<string, string> }

type ClientOptions = {
  apiUrl?: API_URL
  includeAuthHeader?: boolean
  includeDefaultParams?: boolean
  overrideUrl?: string
  /** Error messages that should be logged as info instead of error */
  ignoredMessages?: (string | RegExp)[]
  forceLegacyGateway?: true
}

export type ApiClient = {
  config?: AxiosRequestConfig & {
    onErrorLog?: Record<string, unknown>
    /** This object forces Bugsnag to log any nested object rather omit it */
    forceLogTheseKeys?: Record<string, boolean>
    // this allows an engineer to set data that should be redacted for PII or other reasons
    redaction?: string[]
  }
  options?: ClientOptions & { storeParams?: StoreParams }
  context?: QueryFunctionContext
  ssrContext?: GetServerSidePropsContext
  /** fetcherName metadata field on bugsnag errors. Use to easily tie bugsnag api errors to calling code*/
  fetcherName: string
  /** tag metadata field on bugsnag errors. Use for easier bookmarking of related endpoints*/
  tag?: string
}

type GetBaseURLConfig = {
  ssrContext?: GetServerSidePropsContext
  options?: ClientOptions
}

export const apiEndpoints: Record<API_URL, string> = {
  [API_URL.LEGACY_API_GATEWAY]: legacyApiGatewayURL,
  [API_URL.API_GATEWAY]: apiGatewayURL,
  [API_URL.SELF_API]: '/api',
}

export const getBaseURL = ({ options }: GetBaseURLConfig) => {
  const {
    apiUrl = API_URL.API_GATEWAY,
    overrideUrl,
    forceLegacyGateway = false,
  } = options ?? {}
  if (overrideUrl) {
    return overrideUrl
  }

  if (forceLegacyGateway) {
    return apiEndpoints[API_URL.LEGACY_API_GATEWAY]
  }

  return apiEndpoints[apiUrl]
}

const createClientFetch = ({ ssrContext, options }: GetBaseURLConfig) => {
  const baseURL = getBaseURL({ ssrContext, options })
  if (!baseURL) {
    // this error indicates an ignored upstream typescript violation.
    // maybe we should invent test cases that can reach this error, then fix those bugs, then remove this.
    // optional `undefined`-parameter values are fine; they will still use the initializer.  See:
    // https://www.typescriptlang.org/docs/handbook/2/functions.html#optional-parameters
    throw new Error(`Unsupported apiUrl passed: "${options?.apiUrl}"`)
  }
  return function <T>(config: AxiosRequestConfig) {
    const requestConfig: AxiosRequestConfig = {
      baseURL,
      ...(shouldPassHttpAgents && { httpAgent, httpsAgent }),
      ...config,
    }
    return axios.request<T>(requestConfig)
  }
}

export const clientConfig = async ({
  config = {},
  options,
  context,
  ssrContext,
}: ApiClient): Promise<AxiosRequestConfig> => {
  const {
    storeParams = {},
    includeAuthHeader = true,
    includeDefaultParams = true,
  } = options ?? {}

  if (!ssrContext) ssrContext = context?.meta?.ssrContext

  const headers: AxiosRequestConfig['headers'] = {
    ...getCommonRequestHeaders({ ssrContext, isGQL: false }),
    ...config.headers,
  }

  if (includeAuthHeader) {
    try {
      const accessToken = await getAuthClient(ssrContext).getAccessToken({
        ssrContext,
      })
      if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`
      }
    } catch {
      // porting existing behavior for now; do nothing if there is an error getting access token
      // api call will fail; error from failed fetch will log
    }
  }

  if (!includeDefaultParams) {
    return {
      ...config,
      headers,
    }
  }
  const universal_id = getUniversalId({ ssrContext })

  const params: AxiosRequestConfig['params'] = {
    anonymous_id: ssrContext
      ? getCreateCookieAnonymousId(ssrContext)
      : getAnonymousId(),
    ...(universal_id && { universal_id }),
    ...config.params,
    ...getRequestParams(storeParams),
    ...getDataRightsTrait(ssrContext),
    platform: 'web',
  }

  // ungated endpoints can't accept user_id as a param or else they will
  // assume the user is authenticated and perform different validations
  if (!Number(params.user_id)) delete params.user_id
  return {
    ...config,
    headers,
    params,
  }
}

type FormattedResponse<T> = T extends null | undefined
  ? T
  : T & MarvAdviceByCampaign

type ApiClientRequester<TResponse> = {
  config: AxiosRequestConfig
  fetch: () => Promise<AxiosResponse<FormattedResponse<TResponse>>>
  redaction: string[]
}
// This name distinguishes the current function that also dispatches a request.
export const getApiClientRequestless = async <TResponse>(
  apiClient: ApiClient
): Promise<ApiClientRequester<TResponse>> => {
  const ssrContext = apiClient.context?.meta?.ssrContext || apiClient.ssrContext
  const clientFetch = createClientFetch({
    ssrContext,
    options: apiClient.options,
  })
  const config = await clientConfig(apiClient)
  const fetch = async () => clientFetch<FormattedResponse<TResponse>>(config)
  return { config, fetch, redaction: apiClient.config?.redaction || [] }
}

export const getApiClient = async <ApiResponse>(
  apiClient: ApiClient
): Promise<FormattedResponse<ApiResponse>> => {
  const { fetch, config, redaction } =
    await getApiClientRequestless<ApiResponse>(apiClient)
  const redactedData = { ...config.data }
  if (redaction.length > 0) {
    Object.keys(redactedData).forEach((item) => {
      if (redaction.includes(item)) {
        redactedData[item] = 'redacted'
      }
    })
  }
  const ssrContext = apiClient.context?.meta?.ssrContext || apiClient.ssrContext
  try {
    const results = await fetch()
    const marvAdviceByCampaign = results.data?.marv_advice_by_campaign
    if (marvAdviceByCampaign) {
      updateMarvAdviceByCampaignCookie({ marvAdviceByCampaign, ssrContext })
    }
    return results.data
  } catch (error) {
    if (axios.isCancel(error)) {
      // logging this error locally might be useful
      // eslint-disable-next-line no-console
      if (!isProduction && !isRunningTests) console.error(error)
      throw error
    }

    const errorMetadata = buildErrorMetadata({
      rootObj: config.params ?? {},
      payloadObj: redactedData,
      allowObjKeys: apiClient.config?.forceLogTheseKeys,
    })

    logDataFetchingErrors(apiClient, config, error, ssrContext, errorMetadata)

    throw error
  }
}

export const apiGet = <TResponse>({ config, ...rest }: ApiClient) =>
  getApiClient<TResponse>({ config: { ...config, method: 'GET' }, ...rest })

export const apiDelete = <TResponse>({ config, ...rest }: ApiClient) =>
  getApiClient<TResponse>({ config: { ...config, method: 'DELETE' }, ...rest })

export const apiPost = <TResponse>({ config, ...rest }: ApiClient) =>
  getApiClient<TResponse>({ config: { ...config, method: 'POST' }, ...rest })

export const apiPut = <TResponse>({ config, ...rest }: ApiClient) =>
  getApiClient<TResponse>({ config: { ...config, method: 'PUT' }, ...rest })

export const apiPatch = <TResponse>({ config, ...rest }: ApiClient) =>
  getApiClient<TResponse>({ config: { ...config, method: 'PATCH' }, ...rest })
