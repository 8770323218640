import { queryOptions, useQuery } from '@tanstack/react-query'
import { type SignupPlansPayload } from '@/features/shop/services/DeliveryPlans/types'
import { fetchSignupPlans } from '@/features/shop/services/DeliveryPlans/fetcher'
import { useSignupPlansQueryKeyParams } from '@/features/shop/services/DeliveryPlans/hooks'

const SignupPlans = 'Signup plans'
export type SignupPlansKey = [typeof SignupPlans, SignupPlansPayload]

export const signupPlansQueryOptions = (params: SignupPlansPayload) =>
  queryOptions({
    queryKey: [SignupPlans, params] as const,
    queryFn: fetchSignupPlans,
  })

export const useQuerySignupPlans = ({
  enabled = false,
}: {
  enabled: boolean
}) => {
  const { getQueryKeyParams } = useSignupPlansQueryKeyParams()
  const params = getQueryKeyParams()

  return useQuery({ ...signupPlansQueryOptions(params), enabled })
}
