export const staticColors = {
  primary: '#241239',
  primaryOnLight: '#241239',
  headerBanner: '#502D91', // only used in HeaderBanner, falls back to primary.  Set default for type simplicity
  secondary: '#241239',
  primaryContrast: '#FFFFFF',
  green: '#23CC6B',
  dark_green: '#024533',
  plum: '#241239',
  plum50: '#FAF7FD',
  plum100: '#E4D8F3',
  plum500: '#4A2574',
  plum700: '#190C27',
  green100: '#D5EFB5',
  lime: '#23CC6B',
  lime500: '#25DA73',
  lime600: '#1A9850',
  teal100: '#CDE7E1',
  blue100: '#B8D5EE',
  purple100: '#DED9F7',
  red100: '#F1CCD1',
  orange100: '#FECBBD',
  yellow100: '#FEF2D1',
  green500: '#BAE582',
  teal500: '#038767',
  blue500: '#177cba',
  purple500: '#502D91',
  red400: '#f55180',
  red500: '#B70218',
  orange500: '#EF7C41',
  yellow500: '#FFCC00',
  teal600: '#026C52',
  teal700: '#02513E',
  blue700: '#013160',
  purple700: '#241239',
  red700: '#6E010E',
  orange700: '#AF2F09',
  yellow700: '#806006',
  gray50: '#F9F9F9',
  gray100: '#F3F3F4',
  gray300: '#CECBD0',
  gray400: '#9A949E',
  gray600: '#5C5362',
  gray800: '#382F40',
  gray900: '#180C20',
  darkGray: '#414042',
  oatmeal: '#f7f4ea',
  white: '#ffffff',
  transparency15: '#ffffff15',
  transparency25: '#ffffff25',
  transparency40: '#ffffff40',
  transparency50: '#ffffff50',
  transparency65: '#ffffff65',
  transparency85: '#ffffff85',
  black: '#000000',
  social: {
    fbButtonColor: '#4a6ea8',
    appleButtonColor: '#000000',
    googleButtonColor: '#4285f4',
  },
  purpleCircle360: '#4A2E70',
}

export type StaticColor = keyof typeof staticColors
