import { fetchSubscriptionAuxEligiblePlans } from '@/features/account/services/SubscriptionAux/fetchers'
import { type AuxMembershipPlan } from '@/features/account/services/SubscriptionAux/types'
import { useUserId } from '@/features/account/services/User/hooks'
import { queryOptions, useQuery } from '@tanstack/react-query'
import { minutesToMilliseconds } from 'date-fns'
import { useCallback } from 'react'

const SubscriptionPlansQuery = 'SubscriptionAuxPlans'

export const subscriptionPlansQueryOptions = queryOptions({
  queryKey: [SubscriptionPlansQuery] as const,
  queryFn: fetchSubscriptionAuxEligiblePlans,
  staleTime: minutesToMilliseconds(15),
})

export const useQuerySubscriptionPlans = ({
  planType,
}: {
  planType?: 'upgrade' | 'downgrade'
} = {}) => {
  const userId = useUserId()

  const filterUpgradeDowngradePlans = useCallback(
    (data: AuxMembershipPlan[]) =>
      planType ? data.filter((plan) => plan.plan_type === planType) : data,
    [planType]
  )

  return useQuery({
    ...subscriptionPlansQueryOptions,
    select: filterUpgradeDowngradePlans,
    enabled: Boolean(userId),
  })
}
