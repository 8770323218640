import {
  type ShoppingStore,
  type ServingAddressDetailsResponse,
} from '@/features/shop/services/ShoppingStore/types'
import { apiGet } from '@/features/shared/utils/dataFetching'
import { normalizeStoresResponse } from '@/features/shop/services/ShoppingStore/utils'
import { type QueryFunctionContext } from '@tanstack/react-query'
import {
  type ShoppingStoresKey,
  type ShoppingStoreByIdQueryKey,
} from '@/features/shop/services/ShoppingStore/queries'
import { isOnServer } from '@shared/constants/util'
import { getGuestStoreParamsSSR } from '@/features/shared/utils/dataFetching/guestStoreParams'
import { getCookie } from '@/features/shared/utils/cookies'
import { GuestDataStore } from '@/features/shared/constants/global'

export const fetchShoppingStores = async (
  context: QueryFunctionContext<ShoppingStoresKey>,
  { preferredStoreLocationId }: { preferredStoreLocationId?: number } = {}
) => {
  const [, params] = context.queryKey
  const ssrContext = context.meta?.ssrContext
  // The store location returned from Metropolis may differ from the one provided by the PLA region.
  // Therefore, it is important to pass a preferred store location ID provided by PLA to Metropolis to
  // attempt to retrieve the preferred store we want a guest user to see. Currently, the preferredStoreLocationId
  // parameter is utilized exclusively for the PLA flow involving visitor users. We will also include the preferred
  // store location ID in the request whenever a store location ID is available from the guest store cookie in order
  // to ensure that the returned store data from Metropolis is the store location they have selected.
  const getPreferredStoreLocationId = () => {
    if (preferredStoreLocationId) return preferredStoreLocationId

    if (isOnServer()) {
      if (ssrContext) {
        return getGuestStoreParamsSSR(ssrContext).guestStore?.store_location_id
      }
    }
    return getCookie(GuestDataStore.STORE)?.store_location_id
  }
  const response = await apiGet<ServingAddressDetailsResponse>({
    config: {
      url: 'api/v1/store_locations/serving_address_with_details',
      params: {
        ...params,
        preferred_store_location_ids: getPreferredStoreLocationId(),
      },
    },
    context,
    fetcherName: 'fetchShoppingStores',
  })
  return normalizeStoresResponse(response)
}

export const fetchShoppingStoreById = (
  context: QueryFunctionContext<ShoppingStoreByIdQueryKey>
) => {
  const [, { storeId }] = context.queryKey
  return apiGet<ShoppingStore>({
    config: { url: `api/v1/stores/${storeId}.json` },
    context,
    fetcherName: 'fetchShoppingStoreById',
  })
}
