import { WHITE_LABEL_KEY } from '@/features/shared/constants/whitelabel'
import { logError } from '@/features/shared/utils/logger'

// Medallia doesn't provide proper types for their SDK
type MedalliaSDK = {
  updatePageView: () => void
  isSurveyDisplayed: () => boolean
}

// Medallia is configured from its script to return a Medallia object via
// the KAMPYLE_ONSITE_SDK window constant. We then store properties we wish
// to send to Medallia in a "smiv1" (Segway Medallia Integration Version 1)
// window constant object.
declare global {
  interface Window {
    smiv1?: Record<string, unknown>
    KAMPYLE_ONSITE_SDK?: MedalliaSDK
  }
}

export enum MedalliaUrlParam {
  postCheckout = 'postCheckout=true',
}

let medalliaLoaded = false

async function updatePageView(params = {}) {
  try {
    const medallia = await getMedallia()
    setParams(params)
    medallia.updatePageView()
  } catch (err) {
    logError(err, { customGroupingHash: 'Medallia Error' })
  }
}

async function isSurveyDisplayed() {
  try {
    const medallia = await getMedallia()
    return medallia.isSurveyDisplayed()
  } catch (err) {
    logError(err, { customGroupingHash: 'Medallia Error' })
  }
}

function getMedallia() {
  return new Promise<MedalliaSDK>((resolve) => {
    // if medalliaLoaded is true we have already verified Medallia below
    if (medalliaLoaded) return resolve(window.KAMPYLE_ONSITE_SDK!)
    const interval = setInterval(() => {
      if (
        window.KAMPYLE_ONSITE_SDK &&
        typeof window.KAMPYLE_ONSITE_SDK.updatePageView === 'function'
      ) {
        medalliaLoaded = true
        clearInterval(interval)
        resolve(window.KAMPYLE_ONSITE_SDK)
      }
    }, 100)
  })
}

function setParams(params = {}) {
  const defaults = {
    env: process.env.NEXT_PUBLIC_ENV,
    whiteLabelKey: WHITE_LABEL_KEY,
  }
  if (!window.smiv1) {
    window.smiv1 = {}
  }
  window.smiv1 = {
    ...window.smiv1,
    ...params,
    ...defaults,
  }
}

export const medallia = {
  updatePageView,
  isSurveyDisplayed,
  setParams,
}
