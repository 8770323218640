// disable routing rules cause this is a routes file!
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable shipt/no-hardcoded-routes */

// Do NOT import anything into this file; doing so will
// cause issues when this file is imported into the server runtime
export enum PCIRouteName {
  CHECKOUT = 'CHECKOUT',
  SECURE_ADD_GIFT_CARD_PAYMENT = 'SECURE_ADD_GIFT_CARD_PAYMENT',
  SECURE_ADD_PAYMENT = 'SECURE_ADD_PAYMENT',
  SECURE_ADD_MEMBERSHIP_PAYMENT = 'SECURE_ADD_MEMBERSHIP_PAYMENT',
  SECURE_CHOOSE_MEMBERSHIP_PAYMENT = 'SECURE_CHOOSE_MEMBERSHIP_PAYMENT',
}

export type PCIRoute = {
  url: string
  trackingName: string
  authentication: boolean
  noGuestAccess?: boolean
  storeRequired?: boolean
  pciSecured: true
}

type PCIRouteDictionary = {
  [P in PCIRouteName]: PCIRoute
}

export const pciRouteDictionary = {
  CHECKOUT: {
    url: '/shop/checkout',
    trackingName: 'checkout',
    authentication: true,
    noGuestAccess: true,
    storeRequired: true,
    pciSecured: true,
  },
  SECURE_ADD_GIFT_CARD_PAYMENT: {
    url: '/secure/add-gift-card-payment',
    trackingName: 'gift_add_payment',
    authentication: false,
    pciSecured: true,
  },
  SECURE_ADD_PAYMENT: {
    url: '/secure/add-payment',
    trackingName: 'wallet_add_payment',
    authentication: true,
    pciSecured: true,
  },
  SECURE_ADD_MEMBERSHIP_PAYMENT: {
    url: '/secure/add-membership-payment',
    trackingName: 'membership_add_payment',
    authentication: true,
    pciSecured: true,
  },
  SECURE_CHOOSE_MEMBERSHIP_PAYMENT: {
    url: '/secure/choose-membership-payment',
    trackingName: 'choose_membership_add_payment',
    authentication: true,
    pciSecured: true,
  },
} as const satisfies PCIRouteDictionary
