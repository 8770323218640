import { apiGet } from '@/features/shared/utils/dataFetching'
import { type QueryFunctionContext } from '@tanstack/react-query'
import {
  type MembershipPlanQueryKey,
  type SubscriptionHistoryQueryKey,
} from '@/features/account/services/MembershipPlan/queries'
import {
  type EBTEnrollmentsResponse,
  type StudentEnrollmentsResponse,
  type SubscriptionResponse,
} from '@/features/account/services/MembershipPlan/types'

export const fetchMembershipPlan = (
  context: QueryFunctionContext<MembershipPlanQueryKey>
) => {
  const userId = context.queryKey[1].user_id

  return apiGet<SubscriptionResponse | null>({
    config: {
      url: `v2/customers/${userId}/subscriptions/active`,
    },
    context,
    fetcherName: 'fetchMembershipPlan',
  })
}

export const fetchSubscriptionHistory = (
  context: QueryFunctionContext<SubscriptionHistoryQueryKey>
) => {
  const userId = context.queryKey[1].user_id

  return apiGet<SubscriptionResponse[] | null>({
    config: {
      url: `v2/customers/${userId}/subscriptions`,
    },
    context,
    fetcherName: 'fetchSubscriptionHistory',
  })
}

export const fetchStudentEnrollments = (context: QueryFunctionContext) => {
  return apiGet<StudentEnrollmentsResponse>({
    config: { url: 'member-verification/v1/students/enrollments' },
    context,
    fetcherName: 'fetchStudentEnrollments',
  })
}

export const fetchEBTEnrollments = (context: QueryFunctionContext) => {
  return apiGet<EBTEnrollmentsResponse>({
    config: { url: 'member-verification/v1/ebt/enrollments' },
    context,
    fetcherName: 'fetchEBTEnrollments',
  })
}
