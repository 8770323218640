export const getKey = (e: React.KeyboardEvent | KeyboardEvent) => e.key

export enum keys {
  ENTER = 'Enter',
  SPACE = ' ',
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
  ESCAPE = 'Escape',
  TAB = 'Tab',
  HOME = 'Home',
  END = 'End',
}

export type KeyCode = `${keys}`
