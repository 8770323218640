import { type AuxMembershipPlan } from '@/features/account/services/SubscriptionAux/types'
import { apiGet } from '@/features/shared/utils/dataFetching'
import { type QueryFunctionContext } from '@tanstack/react-query'

export const fetchSubscriptionAuxEligiblePlans = async (
  context: QueryFunctionContext
) => {
  const response = await apiGet<AuxMembershipPlan[]>({
    config: {
      url: 'subscription-auxiliary/v1/plans',
    },
    context,
    fetcherName: 'fetchSubscriptionAuxEligiblePlans',
  })

  const isAutoRenewal = response?.every(
    (plan) =>
      plan.display?.auto_renewal &&
      Array.isArray(plan.display.auto_renewal.notice) &&
      Array.isArray(plan.display.auto_renewal.check_box) &&
      Array.isArray(plan.display.auto_renewal.notes)
  )
  if (!isAutoRenewal) {
    throw new Error(
      'Missing a required property: AuxMembershipPlan.display.auto_renewal'
    )
  }

  return response
}
