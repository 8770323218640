import {
  ORDER_STATUS,
  type OrderStatus,
} from '@/features/shared/constants/global'
import {
  type Address,
  type ValidAddress,
} from '@/features/account/services/Addresses/types'
import { type DeliveryWindow } from '@/features/shop/services/DeliveryWindows/types'
import { type Product } from '@/features/shop/services/Product/types'
import {
  formatDate,
  getDateTimeInfoWithSeconds,
  getDayStrShort,
} from '@/features/shared/utils/date'
import { add, format } from 'date-fns'
import { formatTimeslot } from '@/features/shared/utils/deliveryWindows'

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }
  return null
}

export const formatMaskedPhoneNumber = (phoneNumberString: string) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '')
  return `(***) ***-${cleaned.substring(cleaned.length - 4)}`
}

export const formatAddress = (address: Address | ValidAddress | undefined) => {
  if (!address) return ''
  const { street1, street2, city, state, zip_code: zipCode } = address ?? {}

  return `${street1 ? street1 + ', ' : ''}${street2 ? street2 + ', ' : ''}${
    city ? city + ', ' : ''
  }${state ? state + ' ' : ''}${zipCode || ''}`
}

export const formatPrice = (value = 0, includeDollarSign = true) => {
  const roundedValue = Math.abs(value).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const dollarSign = includeDollarSign ? '$' : ''
  if (value < 0) {
    return `-${dollarSign}${roundedValue}`
  }
  return `${dollarSign}${roundedValue}`
}

export type OrderForFormatting = {
  status: OrderStatus
  delivered_at?: string
  time_start?: string
  time_window: string
  cancelled_at?: string
}
// Eventually we want all delivery times to be displayed in numerical format
// maybe in the future, create a single function that handles all delivery/pickup time formatting
export const formatDeliveryTimeForOrder = (
  order: OrderForFormatting,
  displayInNumericalFormat = false,
  isOrderStatus = false
) => {
  const { status, delivered_at: deliveredAt } = order
  const timeWindow = order?.time_window ?? ''

  if (deliveredAt && status === ORDER_STATUS.DELIVERED) {
    return displayInNumericalFormat
      ? formatDate(deliveredAt, 'M/d/yyyy, h:mm aa')
      : formatDate(new Date(deliveredAt), 'MMM d, yyyy, h:mm aa')
  }
  if (!timeWindow) return ''

  if (timeWindow.includes(':')) {
    if (isOrderStatus) {
      return formatDeliveryTimeForOrderStatus(order)
    }
    const { day, startWithoutMeridiem, end } = formatTimeWindow(timeWindow)
    if (displayInNumericalFormat && order.time_start) {
      const date = formatDate(order.time_start, 'M/d/yyyy')
      return `${date}, ${formatTimeslot(startWithoutMeridiem, end)}`
    } else {
      return `${day}, ${formatTimeslot(startWithoutMeridiem, end)}`
    }
  }
  if (timeWindow.includes(',')) {
    return timeWindow
  }
  return `Today, ${timeWindow}`
}

export const formatTimeWindow = (timeWindow: string) => {
  const [day = '', time = ''] = timeWindow.split(':')
  const [start = '', end = ''] = time.split(' to ')
  const startWithoutMeridiem =
    start.trim().split(start.includes('AM') ? 'AM' : 'PM')[0] ?? ''
  return { day, end, startWithoutMeridiem }
}

export const formatDeliveryTimeForOrderStatus = (order: OrderForFormatting) => {
  const {
    status,
    time_window: timeWindow = '',
    delivered_at: deliveredAt = '',
  } = order || {}
  const { day, end, startWithoutMeridiem } = formatTimeWindow(timeWindow)

  let cancelledAt = ''
  if ('cancelled_at' in order) cancelledAt = order.cancelled_at ?? ''

  switch (status) {
    case ORDER_STATUS.READY_FOR_PICKUP:
      return `${formatTimeslot(startWithoutMeridiem, end)}`
    case ORDER_STATUS.RETURNED:
      return formatDate(new Date(deliveredAt), 'MMM d, yyyy')
    case ORDER_STATUS.CANCELLED:
    case ORDER_STATUS.ABANDONED:
      return formatDate(new Date(cancelledAt), 'MMM d, yyyy')
    default:
      // We want to preserve casing of dates, but lower case for
      // delivery windows that are today or tomorrow
      const formattedDay = /(today|tomorrow)/i.test(day)
        ? day.toLowerCase()
        : day
      return `${formattedDay}, ${formatTimeslot(startWithoutMeridiem, end)}`
  }
}

// returns the first letter of the first and last name in a string
// if only one name is provided, only one initial is returned
export const getInitials = (name: string) => {
  const names = name.split(' ')
  return names.length === 1
    ? names[0]?.charAt(0) || ''
    : (names[0]?.charAt(0) || '') + (names[names.length - 1]?.charAt(0) || '')
}

export const formatNextDeliveryText = (timeSlot?: DeliveryWindow) => {
  if (!timeSlot) return 'Check back soon'

  const date = new Date(timeSlot.date ?? '')
  const dayOfWeek = getDayStrShort(date) || formatDate(date, 'MMM d') // e.g. Apr 1
  return `${dayOfWeek} ${timeSlot.start} to ${timeSlot.end}`
}

export const formatNextDeliveryTextForGuest = (
  timeString?: string,
  includeDay?: boolean
) => {
  if (!timeString) return 'Check back soon'

  const date = new Date(timeString ?? '')
  const dateEnd = add(date, { hours: 1 })
  const startTime = format(date, includeDay ? 'eee h aaa' : 'h aaa')
  const endTime = format(dateEnd, 'h aaa')
  return `${startTime} to ${endTime}`
}

// only used for string values with $
export const parseCurrency = (value: string) => {
  if (value && typeof value === 'string') {
    const match = value.match(/[0-9.]/g)
    if (match) {
      return parseFloat(match.join(''))
    }
  }
  return 0
}

export const convertDateToLocale = (date: string) => {
  if (typeof date !== 'string') return ''
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  })
}

export const formatTimeSaved = (seconds = 0) => {
  const { hours, days, weeks, years } = getDateTimeInfoWithSeconds(seconds)
  if (hours > 9999) {
    if (days > 9999) {
      if (weeks > 9999) {
        return { value: years, text: 'years' }
      } else {
        return { value: weeks, text: 'weeks' }
      }
    } else {
      return { value: days, text: 'days' }
    }
  }
  return { value: hours, text: 'hours' }
}

// SEO Title Tag Structure: [Brand] [Product Name] [Size] | [White Label]
// e.g. - Sabra Roasted Red Pepper Hummus 10 oz | Shipt
export const formatProductPageTitle = (
  product: Product | undefined,
  partnerName: string
) => {
  const { name, size } = product ?? {}
  if (name) {
    return `${name}${size ? ` ${size}` : ''} | ${partnerName}`
  }
  return partnerName
}

// We need a function to add a period to the end of the promo description
// if one was not added in admin. This was requested by design.
export const addPeriodIfNeeded = (text: string) => {
  if (typeof text !== 'string') return text
  if (text === '') return text
  if (text.slice(-1) !== '.') {
    return text + '.'
  }
  return text
}

// We need a function to remove a period to the end of the promo description
export const removePeriodIfNeeded = (text: string) => {
  if (typeof text !== 'string') return text
  if (text.slice(-1) === '.') {
    return text.substring(0, text.length - 1)
  }
  return text
}

export const formatNumberWithCommas = (number: number) => {
  if (typeof number !== 'number') return number
  return number.toLocaleString('en-US')
}

export const toSnakeCase = (string: string) => {
  if (typeof string !== 'string') return string
  return string
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+\d*|\b)|[A-Z]?[a-z]+\d*|[A-Z]|\d+/g)
    ?.map((x) => x.toLowerCase())
    .join('_')
}

export const pluralizer = (word: string, count: number) =>
  count === 1 ? word : word + 's'

export const removeMeridiem = (time: string) => {
  // expects string with Meridiem ex 7:20 AM returns 7:20
  return time.split(' ')[0] ?? ''
}
