import { queryOptions, useQuery } from '@tanstack/react-query'
import { fetchVisaBenefitDetails } from '@/features/account/services/VisaBenefits/fetcher'
import { useUserId } from '@/features/account/services/User/hooks'

export const visaBenefitQueryOptions = (userId: number) =>
  queryOptions({
    queryKey: ['Benefits', userId] as const,
    queryFn: fetchVisaBenefitDetails,
  })
export type VisaBenefitQueryKey = readonly [string, number]

export const useQueryEnrolledBenefit = () => {
  const userId = useUserId() ?? 0

  return useQuery({
    ...visaBenefitQueryOptions(userId),
    enabled: Boolean(userId),
  })
}
