import {
  PromotionTypes,
  type AvailablePromotionsPayload,
  type Promotion,
} from '@/features/shop/services/Campaigns/types'
import { type Shelf } from '@/features/shop/services/Shelves/types'

const allowedTypes = [
  PromotionTypes.DeliveryFeeMember,
  PromotionTypes.DeliveryFeeNonMember,
  PromotionTypes.OrderDiscount,
]

export const shouldDisplay = (
  promotion: Promotion,
  showMembership = false
): boolean => {
  const { type, audience } = promotion ?? {}
  if (!type || !audience) return false
  return [
    ...allowedTypes,
    ...(showMembership ? [PromotionTypes.Membership] : []),
  ].includes(type)
}

export const orderByPromotionTypeAndEffectImportance = (
  promotion1: Promotion,
  promotion2: Promotion
) => {
  const orderedTypes = [
    PromotionTypes.Membership,
    PromotionTypes.OrderDiscount,
    PromotionTypes.DeliveryFeeNonMember,
    PromotionTypes.DeliveryFeeMember,
    undefined,
    null,
  ]
  return promotion1.type === promotion2.type
    ? (promotion2.thresholds?.[0]?.effect ?? 0) -
        (promotion1.thresholds?.[0]?.effect ?? 0)
    : orderedTypes.indexOf(promotion1.type) -
        orderedTypes.indexOf(promotion2.type)
}

export const marketingPromotionsCount = (
  shelf: Shelf<Promotion>,
  showMembership = false
) => {
  return (
    shelf?.items?.filter((promotion) =>
      shouldDisplay(promotion, showMembership)
    ).length || 0
  )
}

export const normalizeCampaignsResponse = (
  response: Promotion[]
): AvailablePromotionsPayload => {
  const payload: AvailablePromotionsPayload = {
    availablePromotions: [],
    dollarTotalQualifyingCredit: 0,
  }

  const promotionTypesToStore = [
    PromotionTypes.DeliveryFeeMember,
    PromotionTypes.DeliveryFeeNonMember,
    PromotionTypes.OrderDiscount,
    PromotionTypes.SpendGet,
    PromotionTypes.Membership,
  ]

  return response
    .filter(({ type }) => type && promotionTypesToStore.includes(type))
    .reduce<AvailablePromotionsPayload>((results, currentPromo) => {
      const promoThresholds = currentPromo?.thresholds?.[0]
      const {
        progress: progressThreshold,
        effect_type,
        effect,
        threshold_amount,
        remaining_amount,
      } = promoThresholds ?? {}
      const progress = currentPromo.progress ?? progressThreshold
      if (progress === 1) {
        if (effect_type === 'credit') {
          results.dollarTotalQualifyingCredit += (effect ?? 0) / 100
        }
      }
      currentPromo = {
        ...currentPromo,
        thresholds: [
          {
            ...promoThresholds,
            progress: progress ?? 0,
            remaining_amount: remaining_amount || threshold_amount,
          },
        ],
      }
      results.availablePromotions.push(currentPromo)

      return results
    }, payload)
}

export const getCpgPromotionByCategoryId = (
  availablePromos: Promotion[],
  categoryId: number
) =>
  availablePromos
    .filter((promo) => promo.type === PromotionTypes.SpendGet)
    .find((promo) => promo.promotion_params?.category_id === categoryId)

export const getOrderDiscountPromotionValues = (promotions?: Promotion[]) => {
  const discountPromo =
    promotions?.find((p) => p.type === PromotionTypes.OrderDiscount) ?? {}

  return {
    isNextOrderDiscount: Boolean(discountPromo?.id),
    progress: discountPromo?.progress ?? 0,
    progressHeader: discountPromo?.progress_header,
    progressSubHeader: discountPromo?.progress_subheader,
  }
}
