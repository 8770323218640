import { type PlanMutationPayload } from '@/features/shop/services/DeliveryPlans/types'
import {
  type MembershipPlanType,
  type MembershipStatus,
  type SubscriptionPlanResponse,
} from '@/features/account/services/MembershipPlan/types'

type Notice = {
  copy: string
  style?: 'strong'
  link_url?: string
}

type AutoRenewal = {
  notice: Notice[]
  check_box: Notice[]
  notes: Notice[][]
}

export enum SubscriptionPlanType {
  STUDENT = 'student',
  EBT = 'ebt',
  STANDARD = 'standard',
  UPGRADE = 'upgrade',
  DOWNGRADE = 'downgrade',
  EARLY_RENEWAL = 'early_renewal',
}

export type AuxMembershipPlan = {
  id: PlanMutationPayload['package_id']
  total_cost_cents: number
  total_base_cents: number
  trial_period_days: number
  plan_type: SubscriptionPlanType
  interval: PlanInterval
  single_delivery_fee_cents: number
  display: {
    name: string
    name_with_article: string
    billing_frequency: string
    billing_interval: string
    price_dollars: string
    original_price_dollars: string
    is_discounted: boolean
    discount_amount_dollars: string
    includes_refund: boolean
    promo_expires_at?: string
    tracking_name?: string
    next_full_charge_at?: string
    auto_renewal: AutoRenewal
  }
}

export type AuxEligiblePlansFetcherNames =
  | 'useQueryUpgradePlans'
  | 'useQueryDowngradePlans'

export type AuxPurchasePlan = {
  id?: string
  status?: MembershipStatus
  type?: MembershipPlanType
  start?: number
  canceled_at?: number
  cancel_at_period_end?: boolean
  current_period_start?: number
  current_period_end?: number
  plan: SubscriptionPlanResponse
  discount?: {
    applies_at?: null
    coupon_id?: string
    created_at?: null
    next_full_charge_at?: null
    percent_off?: null
  }
  trial_end?: number
  trial_start?: number
  refundable?: boolean
}

export type AuxPurchasePayload = {
  credit_card_id: string
}

export enum PlanInterval {
  MONTHLY = 'monthly',
  ANNUAL = 'annual',
}
