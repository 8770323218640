import { apiPost } from '@/features/shared/utils/dataFetching'
import { type OauthTokenAPI } from '@/features/account/services/User/types'
import { getAuthClient } from '@/features/authentication/utils/authentication/client'
import { useMutation } from '@tanstack/react-query'
import {
  type FetchJWTPayload,
  type ResetTokenPayload,
} from '@/features/authentication/services/Authentication/types'
import { trackUserLoginError } from '@/features/shared/analytics/users'
import { toError } from '@/features/shared/utils/dataFetching/utils'
import { stringify } from '@/features/shared/utils/queryString'

export const headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
  'X-User-Type': 'Customer',
}

export const useMutationFetchJWT = () => {
  return useMutation({
    mutationFn: (credentials: FetchJWTPayload) => {
      const { username, password } = credentials
      return apiPost<OauthTokenAPI>({
        config: {
          url: 'auth/v3/oauth/token',
          data: stringify({ username, password, grant_type: 'password' }),
          headers,
        },
        options: {
          includeAuthHeader: false,
          forceLegacyGateway: true,
          ignoredMessages: [
            /Username has already been taken/,
            'Invalid Username or Password',
            'Account is locked because of too many incorrect login attempts',
          ],
        },
        fetcherName: 'useMutationFetchJWT',
      })
    },
    onSuccess: async (data) => getAuthClient().persistence.setAuthData(data),
    onError: (error) => {
      trackUserLoginError(toError(error))
    },
  })
}
export const useMutationResetToken = () =>
  useMutation({
    mutationFn: (resetToken: ResetTokenPayload) =>
      apiPost<void>({
        config: {
          url: 'auth/v2/reset-token',
          data: stringify(resetToken),
          headers,
        },
        options: {
          includeAuthHeader: false,
          forceLegacyGateway: true,
        },
        fetcherName: 'useMutationResetToken',
      }),
  })

export const useMutationAuth0ChangePassword = () => {
  return useMutation({
    mutationFn: async (email: string) => {
      return apiPost<string>({
        config: {
          data: { email, connection: 'Shipt-Member-Database' },
        },
        options: {
          // See Auth0 Authentication API docs - https://auth0.com/docs/api/authentication#change-password
          overrideUrl: `${process.env.NEXT_PUBLIC_SHIPT_AUTH0_ISSUER_URL}/dbconnections/change_password`,
          includeAuthHeader: false,
        },
        fetcherName: 'useMutationAuth0ChangePassword',
      })
    },
  })
}
