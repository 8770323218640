import { type CreditCard } from '@/features/purchase/services/CreditCards/types'
import {
  CardBrand,
  type PaymentMethod,
  PaymentWalletType,
} from '@/features/purchase/services/PaymentMethods/types'
import { type AviatorOrderV2YamlResponsePaymentMethods } from '@/graphql-types'

export type PaymentMethodLike =
  | PaymentMethod
  | AviatorOrderV2YamlResponsePaymentMethods
  | CreditCard

export const formatPaymentMethodName = (paymentMethod: PaymentMethodLike) => {
  if ('option' in paymentMethod && paymentMethod.option === 'payment_wallet') {
    return paymentMethod.paymentWalletType
  } else {
    const { brand = 'Card', display_brand, last_4_digits } = paymentMethod
    let brandName =
      display_brand && display_brand.length > 0 ? display_brand : brand
    if (brand === CardBrand.CashApp) brandName = 'Cash App'
    if (brand === CardBrand.Klarna) brandName = 'Klarna'
    if (brand === CardBrand.Link) brandName = 'Bank'
    const suffix =
      last_4_digits && brand !== CardBrand.Link ? ` ${last_4_digits}` : ''
    return `${brandName}${suffix}`
  }
}

// card expiration dates are not 0-padded on the left so
// they must be padded manually. For example, we get
// expiration dates like '2-2024', '3-2023', '12-2032', etc
export const formatCardExpDate = (card: CreditCard) => {
  const expDate = card?.exp_date
  if (!expDate) return ''

  const date = '0' + expDate
  return date.substring(date.length - 7, date.length).replace('-', '/')
}

export const getPaymentTrackingType = (
  paymentMethod?: PaymentMethod | null
) => {
  if (paymentMethod) {
    if (
      'option' in paymentMethod &&
      paymentMethod.option === 'payment_wallet'
    ) {
      switch (paymentMethod.paymentWalletType) {
        case PaymentWalletType.ApplePay:
          return 'apple_pay'
        case PaymentWalletType.GooglePay:
          return 'google_pay'
      }
    }

    if ('funding' in paymentMethod && paymentMethod.funding === 'credit') {
      return 'credit_card'
    }

    // Fall through for digital payment methods: cashapp, klarna, link
    if ('brand' in paymentMethod && paymentMethod.brand) {
      return paymentMethod.brand
    }
  }
  return 'none'
}
