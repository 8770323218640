import { useStore } from 'jotai'
import { useEffect } from 'react'
import Router from 'next/router'
import { routeDataAtom } from '@/features/shared/state/RouteData/atoms'
import { pciSecuredRouteUrls } from '@shared/constants/routes'
import { useFeatureFlags } from '@/features/shared/services/FeatureFlags/useFeatureFlags'
import { isObject } from '@/features/shared/utils/isObject'

export const PCI_ROUTE_ERROR = { message: 'Aborting route change' }

export const usePciHardRouting = () => {
  const {
    flags: { web_pci_2025_popup_compliance: isPciActive },
  } = useFeatureFlags()
  const jotaiStore = useStore()
  useEffect(() => {
    const isPastUrlPciSecuredRoute = jotaiStore.get(routeDataAtom)?.pciSecured
    if (!isPciActive) return

    const handleUnhandledRejection = (event: PromiseRejectionEvent) => {
      if (
        isObject(event.reason) &&
        event.reason.message === PCI_ROUTE_ERROR.message
      ) {
        event.preventDefault()
        return false
      }
    }
    const handleRouteChange = (url: string) => {
      const urlWithoutParams = url.split('?')[0] ?? ''
      const isNextUrlPciSecuredRoute = pciSecuredRouteUrls.has(urlWithoutParams)
      if (
        (isPastUrlPciSecuredRoute && Router.asPath !== url) ||
        (!isPastUrlPciSecuredRoute &&
          isNextUrlPciSecuredRoute &&
          Router.asPath !== url)
      ) {
        Router.events.emit('routeChangeError')
        window.location.href = url

        throw PCI_ROUTE_ERROR
      }
    }
    Router.events.on('routeChangeStart', handleRouteChange)
    window.addEventListener('unhandledrejection', handleUnhandledRejection)

    return () => {
      Router.events.off('routeChangeStart', handleRouteChange)
      window.removeEventListener('unhandledrejection', handleUnhandledRejection)
    }
  }, [isPciActive, jotaiStore])
}
