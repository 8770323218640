import '@/shims'
import '@/from-entries'
import '@/replace-all'
import 'intersection-observer'
import { startBugsnagAndSetContext } from '@/lib/bugsnag'
import '@/lib/bugsnag-performance'
import {
  useEffect,
  useState,
  Suspense,
  lazy,
  useMemo,
  type ReactNode,
  type ReactElement,
} from 'react'
import dynamic from 'next/dynamic'
import { type NextPage } from 'next'
import { useIsomorphicLayoutEffect } from 'react-use'
import { HydrationBoundary, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { type AppProps } from 'next/app'
import Head from 'next/head'
import styled, { ThemeProvider, StyleSheetManager } from 'styled-components'
import { getAnonymousId } from '@shipt/analytics-member-web'
import { useInitApp } from '@/index'
import { transformRoutesToTitle } from '@/features/shared/utils/transformRoutesToTitle'
import '@/features/shared/styles/react-modal-override.css'
import '../../node_modules/normalize.css/normalize.css'
import { ExperimentWidget } from '@/features/entrypoint/components/Developer/ExperimentWidget'
import { ErrorPage } from '@/features/shared/components/ErrorPage'
import { ThirdPartyScripts } from '@/lib/ThirdPartyScripts'
import { setupReactModal } from '@/lib/setupReactModal'
import { useRoutingFlow } from '@/features/shared/utils/appStart/useRoutingFlow'
import { useRouteChangeTracking } from '@/features/shared/utils/appStart/useRouteChangeTracking'
import { routes } from '@shared/constants/routes'
import { AppFooter } from '@/features/shared/components/AppFooter'
import { useReactQuery } from '@/features/shared/utils/dataFetching/reactQuery/SegwayQueryClient'
import { GlobalStyle } from '@/features/shared/styles/globalStyles'
import { GuestUserContextProvider } from '@/features/shared/context/GuestUserContext'
import { useQueryUser } from '@/features/account/services/User/queries'
import {
  type PageRouteData,
  type CombinedPageProps,
} from '@/features/shared/utils/setPageProps'
import { Provider as JotaiProvider } from 'jotai'
import { useCreateJotaiStore } from '@/features/shared/state'
import { ShiptStaticData } from '@/features/shared/constants/shiptStaticData'
import { ClientOnly } from '@/features/shared/components/ClientOnly'
import { UserProvider as Auth0UserProvider } from '@auth0/nextjs-auth0/client'
import { useSyncUser } from '@/features/authentication/utils/authentication/hooks/useSyncUser'
import { shouldUseNewAuth } from '@/features/authentication/utils/authentication/utils'
import { hasLegacyClientAuthData } from '@/features/authentication/utils/authentication/client/legacy/utils'
import { LazyMotion } from 'framer-motion'
import {
  useLoadFeatureFlagsIntoAtom,
  useSyncFeatureFlagsWithGlobalVariable,
} from '@/features/shared/state/FeatureFlags/hooks'
import {
  useLoadExperimentsIntoAtom,
  useSyncExperimentsWithGlobalVariable,
} from '@/features/shared/state/Experiments/hooks'
import { useContentsquareSyncVars } from '@/lib/contentsquare/hooks'
import { useDataRights } from '@/features/account/services/DataPrivacy/hooks'
import { OptOutDialog } from '@/features/account/components/OptOutDialog'
import { useCleanUrlOfRedirectParams } from '@/features/shared/utils/appStart/useCleanUrlOfRedirectParams'
import { useScrollRestoration } from '@/features/shared/utils/appStart/useScrollRestoration'
import { useLogExcessiveQueryObservers } from '@/features/shared/utils/appStart/useLogExcessiveQueryObservers'
import { useQueryOptOutRestrictions } from '@/features/account/services/DataPrivacy/queries'
import { DialogContainer } from '@/features/shared/components/DialogContainer'
import { useMonitorUser } from '@/features/authentication/utils/authentication/hooks/useMonitorUser'
import { RouteDataContextProvider } from '@/features/shared/context/RouteDataContext'
import { useAuth0MigrationModal } from '@/features/shared/utils/appStart/useAuth0MigrationModal'
import { removeItem } from '@/features/shared/utils/localStorage'
import { ServerSsrContextProvider } from '@/features/shared/context/ServerSsrContextProvider'
import { flushPciTrackingQueue } from '@/features/purchase/components/PciSections/utils'
import { useFeatureFlags } from '@/features/shared/services/FeatureFlags/useFeatureFlags'
import { shouldForwardProp } from '@/features/shared/utils/appStart/shouldForwardPropForStyledComponentV6'
import { usePciHardRouting } from '@/features/shared/utils/appStart/usePciHardRouting'

setupReactModal()

const AuthenticatedUserStatePreloader = dynamic(() =>
  import(
    '@/features/shared/utils/appStart/AuthenticatedUserStatePreloader'
  ).then((m) => m.AuthenticatedUserStatePreloader)
)
const SimpleHeader = dynamic(() =>
  import('@/features/shared/components/Headers/SimpleHeader').then(
    (m) => m.SimpleHeader
  )
)
const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(
    (d) => ({ default: d.ReactQueryDevtools })
  )
)
const Toast = dynamic(
  () => import('@/features/shared/components/Toast').then((m) => m.Toast),
  {
    ssr: false,
  }
)

const loadFeatures = async () =>
  (await import('@/lib/framerMotion')).domAnimation

const DevOnlyJotaiDevTools =
  process.env.NODE_ENV === 'development' &&
  process.env.NEXT_PUBLIC_DEV_TOOLS === 'true'
    ? dynamic(
        () =>
          import('@/lib/jotaiDevTools').then((m) => ({ default: m.DevTools })),
        { ssr: false }
      )
    : null

const RouteChangeAndPciTracking = () => {
  // This will be removed as a part of PCI cleanup https://app.clickup.com/t/36127442/PCI-3200
  const {
    flags: { web_pci_2025_popup_compliance },
  } = useFeatureFlags()

  useEffect(() => {
    web_pci_2025_popup_compliance && flushPciTrackingQueue()
  }, [web_pci_2025_popup_compliance])

  useRouteChangeTracking()
  return null
}
const PciHardRouting = () => {
  usePciHardRouting()
  return null
}

const ThirdPartySideEffects = () => {
  useContentsquareSyncVars()
  useMonitorUser()
  return null
}

const PreloadAppState = () => {
  useLoadFeatureFlagsIntoAtom()
  useLoadExperimentsIntoAtom()
  return null
}

const Auth0MigrationModal = ({ routeData }: { routeData: PageRouteData }) => {
  useAuth0MigrationModal(routeData)
  return null
}

const SyncStateWithTracking = () => {
  useSyncFeatureFlagsWithGlobalVariable()
  useSyncExperimentsWithGlobalVariable()
  return null
}

export type NextPageWithLayout<P = CombinedPageProps, IP = P> = NextPage<
  P,
  IP
> & {
  getHeaderLayout?: (page: ReactElement, props: HeaderLayoutProps) => ReactNode
}
export type HeaderLayoutProps = Pick<AppProps, 'router'>
type AppPropsWithLayout = AppProps<CombinedPageProps> & {
  Component: NextPageWithLayout
}
function StyledApp({ Component, pageProps, router }: AppPropsWithLayout) {
  useScrollRestoration()
  useLogExcessiveQueryObservers()
  useQueryOptOutRestrictions({ notifyOnChangeProps: [] })
  const { routeData } = pageProps
  const [showExperimentDevtools, setShowExperimentDevtools] = useState(false)
  const { error: userError, data: user } = useQueryUser({
    notifyOnChangeProps: ['data', 'error'],
  })
  const { images } = ShiptStaticData
  const title = transformRoutesToTitle(router.pathname, routeData)
  const { favicon } = images
  const initApp = useInitApp()
  const syncUser = useSyncUser()
  useDataRights()

  useEffect(() => {
    window.toggleExperimentDevTools = () =>
      setShowExperimentDevtools((old) => !old)
  }, [])

  // initApp contains client-only code, so we want to run this synchronously with useLayoutEffect
  // This function is called on every page nav. It's important it only _runs_ once (ie per page refresh)!
  useIsomorphicLayoutEffect(() => {
    initApp()
    // This only runs once; run syncUser here for legacy auth
    if (hasLegacyClientAuthData()) {
      syncUser()
    }
  }, [])

  useEffect(() => {
    // Due to issues with 2fa, do not run syncUser here for legacy auth
    if (!hasLegacyClientAuthData()) {
      syncUser()
    }
  }, [syncUser])

  useRoutingFlow(routeData)

  useEffect(() => {
    if (window.branch?.setBranchViewData) {
      window.branch.setBranchViewData({
        data: { web_anonymous_id: getAnonymousId() },
      })
    }
  }, [])

  const isPreviewPage =
    router.route.includes(routes.CMS_MODULE.url) ||
    router.route.includes(routes.CMS_PREVIEW_PAGE.url)

  if (isPreviewPage) {
    return (
      <>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>Preview</title>
        </Head>
        <Component {...pageProps} />
      </>
    )
  }
  // Use the layout defined at the page level, if available
  const getHeaderLayout = Component.getHeaderLayout ?? ((page) => page)

  if (userError) {
    return (
      <>
        <SimpleHeader />
        <ErrorPage showActionButton={false} />
      </>
    )
  }
  return (
    <>
      <ThirdPartySideEffects />
      {!!user?.id && <AuthenticatedUserStatePreloader pageProps={pageProps} />}
      <PreloadAppState />
      <Auth0MigrationModal routeData={routeData} />
      <SyncStateWithTracking />
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="format-detection"
          content="telephone=no, date=no, email=no, address=no"
        />
        <title>{title}</title>
        <link rel="shortcut icon" href={favicon} key="favicon" />
        <ThirdPartyScripts
          disableSegment={!!pageProps.disableSegment}
          isPCI={routeData?.pciSecured || false}
        />
        {/* Prevent authenticated pages from being indexed */}
        {(routeData?.authentication || routeData?.noIndex) && (
          <>
            <meta name="robots" content="noindex" />
            <meta name="googlebot" content="noindex" />
          </>
        )}
      </Head>
      <RouteChangeAndPciTracking />
      <PciHardRouting />
      <Toast />
      {getHeaderLayout(
        pageProps.statusCode ? (
          <BodyContainer id="main-content">
            <ErrorPage statusCode={pageProps.statusCode} />
          </BodyContainer>
        ) : (
          <BodyContainer id="main-content">
            <Component {...pageProps} />
          </BodyContainer>
        ),
        { router }
      )}
      <AppFooter />
      <OptOutDialog />
      {showExperimentDevtools && <ExperimentWidget />}
    </>
  )
}

const BodyContainer = styled.div`
  min-height: 100vh;
  padding-bottom: 1.5rem;
`

export default function App(props: AppProps<CombinedPageProps>) {
  useCleanUrlOfRedirectParams()
  const [showRqDevtools, setShowRqDevtools] = useState(false)
  const queryClient = useReactQuery()
  const { pageProps } = props
  const { reactQueryState, ssrContext, routeData, user } = pageProps

  startBugsnagAndSetContext(routeData)
  const newAuthEnabled = shouldUseNewAuth(ssrContext)

  const sessionFetcher = useMemo(() => {
    if (!newAuthEnabled) {
      // If we are not using new auth (aka we are using legacy auth)
      // We need to override the sessionFetcher in the auth0provider,
      // to prevent calls to /api/auth/me
      return () => Promise.resolve(undefined)
    }
    // If we are using new auth, sessionFetcher should be undefined, so the
    // default sessionFetcher is used in the auth0provider
    return undefined
  }, [newAuthEnabled])

  const auth0User = useMemo(() => {
    if (!newAuthEnabled) {
      return {}
    }
    // If we are using new auth, auth0User will come from server page props.
    // If auth0User is undefined, the default behavior (fetch the user) is used in the auth0provider.
    return user
  }, [newAuthEnabled, user])

  useEffect(() => {
    window.toggleRqDevTools = () => setShowRqDevtools((old) => !old)
    removeItem('state')
    removeItem('REACT_QUERY_OFFLINE_CACHE')
  }, [])
  const jotaiStore = useCreateJotaiStore()

  return (
    <JotaiProvider store={jotaiStore}>
      {DevOnlyJotaiDevTools && (
        <ClientOnly>
          <DevOnlyJotaiDevTools store={jotaiStore} />
        </ClientOnly>
      )}
      <QueryClientProvider client={queryClient}>
        <RouteDataContextProvider routeData={routeData}>
          <GuestUserContextProvider cookies={ssrContext?.req.cookies}>
            <HydrationBoundary state={reactQueryState}>
              <Auth0UserProvider fetcher={sessionFetcher} user={auth0User}>
                <ServerSsrContextProvider ssrContext={ssrContext}>
                  <StyleSheetManager
                    shouldForwardProp={shouldForwardProp}
                    enableVendorPrefixes
                  >
                    <ThemeProvider theme={ShiptStaticData.theme}>
                      <LazyMotion features={loadFeatures} strict>
                        <GlobalStyle />
                        <DialogContainer>
                          <StyledApp {...props} />
                        </DialogContainer>
                      </LazyMotion>
                    </ThemeProvider>
                  </StyleSheetManager>
                </ServerSsrContextProvider>
              </Auth0UserProvider>
            </HydrationBoundary>
          </GuestUserContextProvider>
        </RouteDataContextProvider>
        <ClientOnly>
          <ReactQueryDevtools
            initialIsOpen={false}
            buttonPosition={DevOnlyJotaiDevTools ? 'top-left' : 'bottom-left'}
          />
        </ClientOnly>
        {showRqDevtools && (
          <Suspense fallback={null}>
            <ReactQueryDevtoolsProduction buttonPosition="top-left" />
          </Suspense>
        )}
      </QueryClientProvider>
    </JotaiProvider>
  )
}
