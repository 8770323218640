export const ON_SALE_SHELF_TYPE = 'on sale'
export const BUY_AGAIN_SHELF_TYPE = 'buy again'
export const BUY_AGAIN_SINGLE_RETAILER_TYPE = 'buy_again_single_retailer'
export const RECOMMENDATIONS_SHELF_TYPE = 'recommendations'
export const CATEGORY_SHELF_TYPE = 'category'
export const CATEGORY_SAVINGS_SHELF_TYPE = 'category_savings'
export const BUY_AGAIN_SAVINGS_SHELF_TYPE = 'buy_again_savings'
export const LARGE_FEATURE_PROMOTIONS_SHELF_TYPE = 'large promotions'
export const DISCOVERY_PRODUCTS_SHELF_TYPE = 'discovery products'
export const DID_YOU_FORGET_SHELF_TYPE = 'did you forget'
export const CAMPAIGN_PROMOS_SHELF_TYPE = 'campaign_promos'
export const NEW_DEALS_THIS_WEEK_SHELF_TYPE = 'new_deals_this_week'
export const COUPONS_SHELF_TYPE = 'coupons'
export const COUPONS_FOR_YOU_SHELF_TYPE = 'coupons_for_you'
export const FEATURED_PROMOTIONS_SHELF_TYPE = 'featured promotion category'
export const COMMERCE_PRODUCT_BANNER_SHELF_TYPE = 'commerce_product_banner'
export const STATIC_BANNER_SHELF_TYPE = 'static banner'
export const SHOPPING_LIST_SHELF_TYPE = 'shopping_list'
export const SHIPT_AI_SEARCH_GENERATED_TYPE = 'search_generated_shelf'
export const TRENDING_ITEMS_SHELF_TYPE = 'trending_items'
export const MULTI_RETAILER_PRODUCT_SHELVES_TYPE =
  'multi_retailer_product_shelves'
export const MULTI_RETAILER_PRODUCT_SHELF_TYPE = 'multi_retailer_product_shelf'
export const PROGRESSIVE_REVEAL_PRODUCT_SHELF_TYPE =
  'progressive_reveal_product_shelf'

export enum SHELF_RENDER_TYPE {
  PRODUCT = 'product',
  BUY_AGAIN_SINGLE_RETAILER = 'buy_again_single_retailer',
  LARGE_FEATURE_PROMOTIONS = 'large promotions',
  FEATURED_PROMOTIONS = 'featured promotions',
  CAMPAIGN_PROMOS = 'campaign_promos',
  LANDSCAPE_PRODUCT = 'landscape product',
  COUPONS = 'coupons',
  COMMERCE_PRODUCT_BANNER_SHELF = 'commerce_product_banner',
  STATIC_BANNER = 'static banner',
  MULTI_RETAILER_PRODUCT_SHELVES = 'multi_retailer_product_shelves',
  MULTI_RETAILER_PRODUCT_SHELF = 'multi_retailer_product_shelf',
}

export const SHELF_ITEM_WIDTH = 154
export const HERO_SHELF_ITEM_WIDTH = 264
