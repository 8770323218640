import axios from 'axios'
import { type OauthTokenAPI } from '@/features/account/services/User/types'
import { logError } from '@/features/shared/utils/logger'
import { type GetServerSidePropsContext } from 'next'
import { isOnServer } from '@shared/constants/util'
import ServerCookies from 'cookies'
import { cookieName, cookieOptions } from '@shared/constants/auth'
import { v4 as uuidv4 } from 'uuid'
import { getItem, setItem } from '@/features/shared/utils/localStorage'
import { getServerAuthData } from '@/features/authentication/utils/authentication/client/legacy/utils'
import { noop } from '@/features/shared/utils/noop'

const authEndpoint = '/api/auth'

let _authData: OauthTokenAPI | undefined | Record<string, unknown>
export const AUTH_INSTANCE_ID_KEY = 's.aiid'
const authInstanceId = uuidv4()

const _modifyPersistedAuthData = (
  val?: OauthTokenAPI | Record<string, unknown>
) => {
  _authData = val
  setItem(AUTH_INSTANCE_ID_KEY, authInstanceId)
}

export async function _getAuthData(
  ssrContext?: GetServerSidePropsContext
): Promise<Partial<OauthTokenAPI>> {
  // if SSR, use server context for auth data instead
  if (ssrContext) return getServerAuthData(ssrContext)

  // If we have stored auth data and it was last updated by another auth instance
  // reset our local stored auth data, so that it is refetched from the server
  // Only do this if there is a "last updated by" instance id available (in case LS is blocked)
  const lastUpdatingInstance = getItem(AUTH_INSTANCE_ID_KEY)
  if (
    _authData &&
    lastUpdatingInstance &&
    lastUpdatingInstance !== authInstanceId
  ) {
    _authData = undefined
  } else if (_authData) {
    return _authData
  }
  return axios
    .get<OauthTokenAPI>(authEndpoint)
    .then((response) => {
      _modifyPersistedAuthData(response.data)
      return response.data
    })
    .catch(() => {
      _modifyPersistedAuthData({})
      return {}
    })
}

export function _setAuthData(
  authData: Partial<OauthTokenAPI>,
  ssrContext?: GetServerSidePropsContext
) {
  if (ssrContext?.req && ssrContext?.res) {
    const cookies = new ServerCookies(ssrContext.req, ssrContext.res)
    const encodedCookie = encodeURIComponent('j:' + JSON.stringify(authData))
    cookies.set(cookieName, encodedCookie, cookieOptions)
    return Promise.resolve()
  }
  _modifyPersistedAuthData(authData)
  return axios(authEndpoint, {
    method: 'POST',
    data: authData,
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(noop)
    .catch((err) => {
      logError(err, { apiUrl: authEndpoint, method: 'POST' })
    })
}

export async function _deleteAuthData() {
  if (isOnServer()) return
  _modifyPersistedAuthData(undefined)
  return axios
    .delete(authEndpoint)
    .then(noop)
    .catch((err) => {
      logError(err, { apiUrl: authEndpoint, method: 'DELETE' })
    })
}
