export enum SubscriptionId {
  GROCERY_YEARLY = 'GROCERY_YEARLY',
  GROCERY_MONTHLY = 'GROCERY_MONTHLY',
  GROCERY_MONTHLY_10_99 = 'GROCERY_MONTHLY_10_99',
  VISA_MONTHLY = 'VISA_MONTHLY',
  STUDENT_MONTHLY = 'STUDENT_MONTHLY_4_99',
  SNAP_EBT_MONTHLY = 'EBT_MONTHLY_4_99',
}

export enum MembershipInterval {
  year = 'year',
  month = 'month',
}

export enum MembershipStatus {
  trialing = 'trialing',
  active = 'active',
}

export enum MembershipPlanType {
  CIRCLE_360 = 'Trident.27',
}

export type PlanType =
  | 'Annual'
  | 'Monthly'
  | 'Membership'
  | `${number} Year`
  | `${number} Month`

export type PlanName = PlanType | 'Student' | 'SNAP EBT'

export type Subscription = {
  id: SubscriptionId | ''
  name: string
  trialDays: number
  metadata: unknown
  price: number
  startDate: string
  currentPeriodStartDate: string
  renewalDate: string
  trialStart: string
  trialEnd: string
  duration: string
  cancelAtPeriodEnd: boolean
  interval: MembershipInterval | ''
  intervalCount: number
  refundable: boolean
  status: MembershipStatus | ''
  schedule: SubscriptionSchedule
  type: MembershipPlanType | ''
}

export type SubscriptionPlanResponse = {
  id?: SubscriptionId | null
  name?: string | null
  trial_period_days?: number | null
  amount?: number | null
  interval?: MembershipInterval | null
  interval_count?: number | null
  currency?: string | null
  duration?: string | null
  metadata?: unknown
}

type SubscriptionDiscount = {
  coupon_id?: string | null
}

export type SubscriptionResponse = {
  status?: MembershipStatus | null
  plan?: SubscriptionPlanResponse | null
  current_period_end?: number | null
  current_period_start?: number | null
  cancel_at_period_end?: boolean | null
  schedule?: SubscriptionSchedule | null
  start?: number | null
  trial_end?: number | null
  trial_start?: number | null
  refundable?: boolean | null
  type?: MembershipPlanType | null
  discount?: SubscriptionDiscount | null
}

export type SubscriptionPhasePlan = {
  plan?: SubscriptionId | null
  quantity?: number | null
}

export type SubscriptionPhase = {
  plans?: SubscriptionPhasePlan[] | null
  start_date?: number | null
  end_date?: number | null
  trial_end?: number | null
  coupon?: string | null
  // the subscriptions service does not currently return
  // amount but may at some point in the future
  amount?: number | null
}

export type SubscriptionSchedule = {
  external_id?: string | null
  canceled_at?: number | null
  completed_at?: number | null
  created?: number | null
  current_phase?: {
    start_date?: number | null
    end_date?: number | null
  } | null
  end_behavior?: string | null
  phases?: SubscriptionPhase[] | null
  status?: string | null
}

export type SignupPlan = {
  credit_amount?: number | null
  duration?: string | null
  id?: number | null
  interval?: MembershipInterval | null
  membership_discount?: number | null
  metadata?: (string | null)[] | null
  name?: string | null
  price?: number | null
  signup_promotion_id?: number | null
  stripe_coupon_id?: string | null
  stripe_plan_id?: string | null
  trial_days?: number | null
}

export type SignupPlansResponse = {
  signup_plans?: SignupPlan[] | null
}

export enum CancellationSurveyReason {
  COST = 'Cost of service is too high',
  ORDER_EXPERIENCE = 'Poor order experience',
  DONT_USE = "I don't use the service enough",
  OTHER = 'Other',
}

export type StudentVerificationCodeResponse = {
  is_valid: boolean
  reverify_before?: string
  reason?: string
}

export type StudentEnrollmentsResponse = {
  user_id?: string | null
  email?: string | null
  status?: string | null
  email_validated?: boolean | null
  email_verified?: boolean | null
  matched_school?: string | null
  last_verified_at?: string | null
  expires_at?: string | null
  reverify_before?: string | null
}

export type EBTEnrollmentsResponse = {
  user_id?: string
  card_id?: string
  card_validated?: boolean
  subscription_status?: string
  last_verified_at?: string
  reverify_before?: string
}

export type CreateEBTEnrollmentsResponse = EBTEnrollmentsResponse & {
  ineligible_reason?: string
}

export type CreateEBTEnrollmentsPayload = {
  ebtToken?: string
  ebtCardId?: string
}
